import ar_EG from "antd/lib/locale/ar_EG";
import cs_CZ from "antd/lib/locale/cs_CZ";
import da_DK from "antd/lib/locale/da_DK";
import de_DE from "antd/lib/locale/de_DE";
import en_GB from "antd/lib/locale/en_GB";
import en_US from "antd/lib/locale/en_US";
import es_ES from "antd/lib/locale/es_ES";
import fr_FR from "antd/lib/locale/fr_FR";
import it_IT from "antd/lib/locale/it_IT";
import nl_NL from "antd/lib/locale/nl_NL";
import tr_TR from "antd/lib/locale/tr_TR";
import { Guid } from "guid-typescript";
import _ from "lodash";
import moment from "moment";
import printJS from "print-js";
import QRCode from "qrcode";
import querystringify from "querystringify";
import qz from "qz-tray";
import { store } from "react-notifications-component";
import generatePDF, { Resolution } from "react-to-pdf";
import { TOTP } from "totp-generator";
import { v4 as uuid } from "uuid";
import VCard from "vcard-creator";
import { setTimeout } from "worker-timers";
import LocalStorageService from "../../auth/local-storage-service";
import { IChangePasswordDto } from "../../models/dto/change-password.dto";
import { ICreateUserWithPasswordDto } from "../../models/dto/create-user-with-password";
import { ICreateUserDto } from "../../models/dto/createuser";
import { IForgotPasswordDto } from "../../models/dto/forgotpassword.dto";
import { IIyzicoAddressDto, IIyzicoCheckoutFormDto } from "../../models/dto/iyzico.dto";
import { IResetPasswordWithTokenDto } from "../../models/dto/reset-password-with-token";
import { IRestResult } from "../../models/dto/rest-result.dto";
import { ISignupDto } from "../../models/dto/signup.dto";
import { IStripePayment } from "../../models/dto/stripe-payment";
import {
  IStripeCaptureDto,
  IStripeChargePaymentMethodDto,
  IStripeConnectedAccountDto,
  IStripeConnectedAccountOnboardingDto,
  IStripeConnectedAccountPayout,
  IStripeConnectedAccountWithdraw,
  IStripeCreateConnectedAccountDto,
  IStripeDeletePaymentMethodDto,
  IStripeMasterAccountDetailsDto,
  IStripeMasterAccountDto,
  IStripePaymentDto,
  IStripeRefundDto,
  IStripeUpdateCustomerDto
} from "../../models/dto/stripe.dto";
import { IUpdateUserStartingScreen } from "../../models/dto/update-user-starting-screen.dto";
import { IUpdateUsernameDto } from "../../models/dto/update-username.dto";
import { IUserRoleDto } from "../../models/dto/userrole.dto";
import { ITaskDataParameters, getDictionaryValueByKey } from "../../models/dto/workflow-task.dto";
import { EAccountStatus } from "../../models/enum/auth";
import ServerFilesService from "../../services/files-download-service";
import HelperService from "../../services/helper-service";
import { IyzicoService } from "../../services/iyzico-service";
import { LanguageService } from "../../services/language-service";
import NotificationService from "../../services/notification-service";
import { StripeService } from "../../services/stripe-service";
import { UserService } from "../../services/user-service";
import { WorkflowService } from "../../services/workflow-service";
import { OTP_ALGORITHM, OTP_DIGITS, OTP_PERIOD, OTP_SECRET_KEY } from "../json/otp.json";
import { CryptoJSHelper } from "./cryptojs-helper";
import { KuikaAppManager } from "./kuika-app-manager";
import { KMainFunctions } from "./main-functions";

declare let window: Window & { [key: string]: any };
declare let KEYUTIL: any;
declare let google: any;
declare let KJUR: any;
declare let stob64: any;
declare let hextorstr: any;
let TOKEN = "";
let USER = "";
let enteredPassword = "";

export class ReactSystemFunctions {
  public static convertToTypeByName(data: any, targetTypeName: string): any {
    if ((data == null || data == undefined) && targetTypeName == "boolean") {
      return false;
    }
    if (data == null) return null;
    targetTypeName = targetTypeName.toLowerCase();
    if (targetTypeName === "string" || targetTypeName === "string?")
      return data !== undefined && data !== null ? data.toString() : "";
    if (targetTypeName === "guid" || targetTypeName === "guid?") {
      if (data !== undefined && data !== null && Guid.isGuid(data)) {
        return data.toString();
      }
      return null;
    }
    if (targetTypeName === "boolean" || targetTypeName === "boolean?")
      return !!(data && data.toString && data.toString().toLowerCase() === "true");
    if (targetTypeName === "date" || targetTypeName === "date?") return this.getDate(data);
    if (targetTypeName === "number" || targetTypeName === "number?")
      return data !== undefined && data !== null && data !== "" ? data : null;
    return data;
  }

  static getDate = (data: string) => {
    if (data !== undefined && data !== null && data !== "") {
      const date = moment(data);

      if (date.hours() === 0 && date.minutes() === 0 && date.seconds() === 0 && date.milliseconds() === 0) {
        const utcOffset = date.utcOffset() === 0 ? date.utcOffset() : date.utcOffset() / 60;
        date.add(utcOffset, "hours");
      }

      return date;
    }

    return null;
  };

  // public static async listMyTasks(that: any, showPool: any, page: any, pageSize: any, searchKey: any) {
  //   const user = that.props?.context?.userInfo?.username;
  //   if (user === undefined) {
  //     return [];
  //   }
  //   const request = {
  //     user,
  //     app: window.kuika.info.applicationName,
  //     searchKey,
  //     showPool,
  //     page,
  //     pageSize
  //   };
  //   const tasks = await WorkflowService.ListMyTasks(request);
  //   return tasks;
  // }

  // public static async listMyRequests(
  //   that: any,
  //   showCompleted: any,
  //   page: any,
  //   pageSize: any,
  //   processName: any,
  //   searchKey: any
  // ) {
  //   const user = that.props?.context?.userInfo?.username;
  //   if (user === undefined) {
  //     return [];
  //   }
  //   const request = {
  //     user,
  //     app: window.kuika.info.applicationName,
  //     searchKey,
  //     processName,
  //     showCompleted,
  //     page,
  //     pageSize
  //   };
  //   const tasks = await WorkflowService.ListMyRequests(request);
  //   return tasks;
  // }

  // public static async getTaskDetail(stepInstance: string) {
  //   const history = await WorkflowService.GetTaskDetail(stepInstance.toString());
  //   return history;
  // }

  // public static async showHistory(processInstance: string) {
  //   const history = await WorkflowService.ShowWorkflowHistory(processInstance);
  //   return history;
  // }

  // Authentication
  // signup({a0}, {a1}, {a2}, {a3})

  public static async signup(
    _screen: any,
    username: string,
    password: string,
    firstName: string,
    lastName: string,
    confirmEmail?: boolean,
    dontSendEmail?: boolean,
    phoneNumber?: string,
    language?: string
  ) {
    const credientials = {
      username,
      password,
      firstName,
      lastName,
      VerifyEMailAddress: confirmEmail || false,
      dontSendEmail: dontSendEmail || false,
      phoneNumber: phoneNumber || "",
      language: language || ""
    } as ISignupDto;
    LocalStorageService.clearToken();
    window.userInfo = undefined;
    const res = await UserService.SignUp(credientials);
    USER = username;
    enteredPassword = password;
    if (res?.data?.status !== undefined) {
      LocalStorageService.setLoginStatus(res.data.status);
      LocalStorageService.setVerificationUserName(
        res.data.status === EAccountStatus.TwoFactorAuthentication ? username : ""
      );
    }
    return res;
  }

  public static async isVerificationCodeValid(_screen: any, verificationCode: string) {
    TOKEN = verificationCode;
    const loginStatus = LocalStorageService.getLoginStatus();
    if (OTP_SECRET_KEY) {
      const userName = USER || LocalStorageService.getVerificationUserName();
      const otp = this.generateOTP(userName || "");
      if (otp === verificationCode) {
        return true;
      }
    }
    if (loginStatus === EAccountStatus.TwoFactorAuthentication.toString()) return;

    const res = await UserService.VerifyVerificationCode({ username: USER, verificationCode });
    if (KMainFunctions.IsResStatus2XX(res)) {
      LocalStorageService.setVerificationStatus("true");
      return true;
    }
    LocalStorageService.setVerificationStatus("false");
    return false;
  }

  public static async setPasswordWithVerificationCode(
    _screen: any,
    userName: string,
    verificationCode: string,
    newPassword: string,
    language?: string
  ) {
    const res = await UserService.ResetPasswordWithToken({
      UserName: userName,
      newPassword,
      token: verificationCode,
      dontSendEmail: false,
      language: language || ""
    });
    if (KMainFunctions.IsResStatus2XX(res)) {
      LocalStorageService.setResetPasswordStatus(EAccountStatus.Valid);
      return true;
    }

    LocalStorageService.setResetPasswordStatus(EAccountStatus.ForceResetPassword);
    return false;
  }

  public static async encryptedSignin(username: string, password: string) {
    USER = username;
    TOKEN = password;
    enteredPassword = password;

    const encyriptedUserName = CryptoJSHelper.encryptToAESAndEncode(username);
    const encyriptedPassword = CryptoJSHelper.encryptToAESAndEncode(password);
    const res = await this.signin(undefined, encyriptedUserName, encyriptedPassword, true);
    return res;
  }

  public static async signin(
    _screen: any,
    username: string,
    password: string,
    isEncrypted: boolean = false
  ): Promise<any> {
    let loginFunction = UserService.Login;
    if (!isEncrypted) {
      USER = username;
      enteredPassword = password;
      TOKEN = password;
    } else {
      loginFunction = UserService.EncryptedLogin;
    }

    await loginFunction({
      username,
      password
    }).then(
      (res) => {
        LocalStorageService.setLoginStatus(res.data?.status?.toString());
        LocalStorageService.setVerificationUserName(
          res.data?.status === EAccountStatus.TwoFactorAuthentication ? username : ""
        );
        return res;
      },
      (error) => {
        if (
          error.message === "Your password has expired" ||
          error.message === "Şifrenizin süresi doldu" ||
          error.message === "Votre mot de passe a expiré"
        ) {
          KMainFunctions.displayWarningNotification(error.message);
          LocalStorageService.setLoginStatus("1");
        }

        if (error.response?.data?.message) {
          alert(error.response.data.message);
          window.location.reload();
        }
      }
    );
    return {};
  }

  public static async signinWithVerificationCode(): Promise<any> {
    await UserService.LoginWithVerificationCode({
      username: USER,
      verificationCode: TOKEN
    }).then(
      (res) => {
        if (res.data?.status) LocalStorageService.setLoginStatus(res.data?.status?.toString());
        return res;
      },
      (error) => {
        if (error.response?.data?.message) {
          alert(error.response.data.message);
          window.location.reload();
        }
      }
    );
    return {};
  }

  public static async goToNextScreenAfterSignup(screen: any) {
    const status = LocalStorageService.getLoginStatus();
    if (status == EAccountStatus.ShouldActivated.toString()) {
      screen.props.navigator.goToVerificationCodeScreen();
    } else if (status == EAccountStatus.Valid.toString()) {
      await this.signin(this, USER, enteredPassword);
      this.goToNextScreenAfterLogin(screen);
    }
    return true;
  }

  public static goToNextScreenAfterLogin(screen: any) {
    const status = LocalStorageService.getLoginStatus();
    if (status == EAccountStatus.ForceResetPassword.toString()) {
      screen.props.navigator.goToResetPasswordScreen();
    } else if (
      status == EAccountStatus.ShouldActivated.toString() ||
      status == EAccountStatus.TwoFactorAuthentication.toString()
    ) {
      screen.props.navigator.goToVerificationCodeScreen();
    } else if (status == EAccountStatus.Valid.toString()) {
      screen.props.navigator.gotoStartingScreen();
    }
    return true;
  }

  public static goToSignInActivity(screen: any) {
    if (LocalStorageService.getResetPasswordStatus() === EAccountStatus.ForceResetPassword.toString()) {
      LocalStorageService.setResetPasswordStatus("");
      return;
    }

    if (LocalStorageService.getResetPasswordStatus() == EAccountStatus.Valid.toString()) {
      LocalStorageService.setResetPasswordStatus("");
      HelperService.gotoLogin();
    } else {
      localStorage.clear();
      LocalStorageService.clearSessionStorage();
      window.userInfo = undefined;
      screen.props.navigator.gotoStartingScreen("/");
    }
  }

  public static async goToNextScreenAfterVerifyCode(screen: any) {
    const status = LocalStorageService.getLoginStatus();
    const verificationStatus = LocalStorageService.getVerificationStatus();
    if (status == EAccountStatus.ShouldActivated.toString()) {
      await this.signin(this, USER, enteredPassword);
      this.goToNextScreenAfterLogin(screen);
    } else if (status == EAccountStatus.TwoFactorAuthentication.toString()) {
      await this.signinWithVerificationCode();
      this.goToNextScreenAfterLogin(screen);
    } else if (verificationStatus === "true") {
      screen.props.navigator.goToResetPasswordScreen();
    }
    return true;
  }

  public static goToVerificationCodeActivity(screen: {
    props: { navigator: { goToVerificationCodeScreen: () => void } };
  }) {
    const email = LocalStorageService.getForgotPasswordEmail();
    if (email) screen.props.navigator.goToVerificationCodeScreen();
  }

  public static async forgotPassword(
    _screen: any,
    username: string,
    dontSendEmail?: boolean,
    language?: string
  ): Promise<any> {
    USER = username;
    const credientials = {
      email: username,
      dontSendEmail: dontSendEmail || false,
      language: language || ""
    } as IForgotPasswordDto;

    await UserService.ForgotPassword(credientials);
  }

  public static async createUser(
    username: string,
    firstName: string,
    lastName: string,
    dontSendEmail?: boolean,
    phoneNumber?: string,
    language?: string
  ): Promise<any> {
    const credientials = {
      username,
      firstName,
      lastName,
      dontSendEmail: dontSendEmail || false,
      phoneNumber: phoneNumber || "",
      language: language || ""
    } as ICreateUserDto;

    await UserService.CreateUser(credientials);
  }

  public static createUserWithPassword(
    username: string,
    password: string,
    firstName: string,
    lastName: string,
    dontSendEmail?: boolean,
    phoneNumber?: string,
    language?: string
  ): any {
    const credientials = {
      username,
      password,
      firstName,
      lastName,
      dontSendEmail: dontSendEmail || false,
      phoneNumber: phoneNumber || "",
      language: language || ""
    } as ICreateUserWithPasswordDto;

    UserService.CreateUserWithPassword(credientials);
  }

  public static async resetPassword(
    _screen: any,
    newpassword: string,
    dontSendEmail?: boolean,
    language?: string
  ): Promise<void> {
    const credientials = {
      newPassword: newpassword,
      token: TOKEN,
      UserName: USER,
      dontSendEmail: dontSendEmail || false,
      language: language || ""
    } as IResetPasswordWithTokenDto;
    enteredPassword = newpassword;
    const res = await UserService.ResetPassword(credientials);

    if (KMainFunctions.IsResStatus2XX(res)) {
      LocalStorageService.setResetPasswordStatus(EAccountStatus.Valid);
    } else {
      LocalStorageService.setResetPasswordStatus(EAccountStatus.ForceResetPassword);
    }
  }

  public static async addUserToRole(roleName: string, userName: string) {
    const credientials = {
      roleName,
      userName
    } as IUserRoleDto;

    await UserService.AddUserToRole(credientials);
  }

  public static async removeUserFromRole(roleName: string, userName: string) {
    const credientials = {
      roleName,
      userName
    } as IUserRoleDto;

    await UserService.RemoveUserFromRole(credientials);
  }

  public static async enableUser(userName: string): Promise<any> {
    let data = (await UserService.EnableUser(userName)).data;
    return data;
  }

  public static async disableUser(userName: string): Promise<any> {
    let data = (await UserService.DisableUser(userName)).data;
    return data;
  }

  // signin: _signin,
  // signup: _signup,
  // createUser: _createUser,
  // getMyUsername: _getMyUsername,
  // sendResetPasswordMail: _sendResetPasswordMail,
  // resetPassword: _resetPassword,
  // changePassword: _changePassword,
  // logout: _logout,
  // hasRole: _hasRole,
  // hasOneOfRoles: _hasOneOfRoles,

  public static sendResetPasswordMail(_val: string) {
    console.log("TODO!");
  }

  // Convertion
  public static dateAddYears(date: any, yearsToAdd: any) {
    const d = moment(date);
    d.add(yearsToAdd, "years");
    return d;
  }

  public static dateAddDays(date: any, daysToAdd: any) {
    const d = moment(date);
    d.add(daysToAdd, "days");
    return d;
  }

  public static dateAddMinutes(date: any, minutesToAdd: any) {
    const d = moment(date);
    d.add(minutesToAdd, "minutes");
    return d;
  }

  public static dateAddHours(date: any, hoursToAdd: any) {
    const d = moment(date);
    d.add(hoursToAdd, "hours");
    return d;
  }

  public static dateDiffInMinutes(date1: any, date2: any) {
    const a = moment(date1);
    const b = moment(date2);

    return b.diff(a, "minutes");
  }

  public static dateDiffInHours(date1: any, date2: any) {
    const a = moment(date1);
    const b = moment(date2);

    return b.diff(a, "hours");
  }

  public static dateDiffInDays(date1: any, date2: any) {
    const a = moment(date1);
    const b = moment(date2);

    return b.diff(a, "days");
  }

  public static value(
    screen: any,
    val: any,
    varName?: string,
    actionName?: string,
    pkFieldName?: string,
    targetField?: string
  ) {
    if (
      actionName &&
      actionName.length > 0 &&
      pkFieldName &&
      pkFieldName.length > 0 &&
      targetField &&
      targetField.length > 0 &&
      pkFieldName !== targetField
    ) {
      if (
        varName != undefined &&
        screen[`${val}_kuikaSelectBoxRef`] != undefined &&
        screen[`${val}_kuikaSelectBoxRef`] != null &&
        screen[`${val}_kuikaSelectBoxRef`].current != undefined &&
        screen[`${val}_kuikaSelectBoxRef`].current != null &&
        screen[`${val}_kuikaSelectBoxRef`].current.getAllData != undefined
      ) {
        const selectBoxOptionsProp = screen[`${val}_kuikaSelectBoxRef`].current.getAllData();
        if (selectBoxOptionsProp && selectBoxOptionsProp.find) {
          const result = selectBoxOptionsProp.find(
            (x: { [x: string]: any }) => x[pkFieldName] == screen.props.form.getFieldValue(val)
          );
          if (result && result[targetField] !== undefined) return result[targetField];
        }
      } else {
        const result = screen.state[actionName].find(
          (x: { [x: string]: any }) => x[pkFieldName] == screen.props.form.getFieldValue(val)
        );
        if (result && result[targetField] !== undefined) return result[targetField];
      }
    }
    if (
      varName != undefined &&
      screen[`${val}_kuikaMultiSelectRef`] != undefined &&
      screen[`${val}_kuikaMultiSelectRef`] != null &&
      screen[`${val}_kuikaMultiSelectRef`].current != undefined &&
      screen[`${val}_kuikaMultiSelectRef`].current != null &&
      screen[`${val}_kuikaMultiSelectRef`].current.getAllData != undefined
    ) {
      const selectBoxOptionsProp = screen[`${val}_kuikaMultiSelectRef`].current.getAllData();
      if (selectBoxOptionsProp && selectBoxOptionsProp.find) {
        const result = selectBoxOptionsProp?.filter((x: { [x: string]: any }) =>
          screen.props.form.getFieldValue?.(val)?.includes?.(x?.[pkFieldName])
        );
        if (result && result.length > 0 && result?.[0] && result?.[0]?.[targetField] !== undefined) {
          return result?.map?.((x: { [x: string]: any }) => x?.[targetField]);
        }
      }
    }

    if (
      varName != undefined &&
      screen[`${val}_kuikaNoneRef`] != undefined &&
      screen[`${val}_kuikaNoneRef`] != null &&
      screen[`${val}_kuikaNoneRef`].current != undefined &&
      screen[`${val}_kuikaNoneRef`].current != null
    ) {
      if (varName == "fileId") return screen[`${val}_kuikaNoneRef`].current.state.fileGuid;
      if (varName == "fileName") return screen[`${val}_kuikaNoneRef`].current.state.fileToBeUpload.name;
    }

    if (
      varName != undefined &&
      screen[`${val}_kuikaTableRef`] != undefined &&
      screen[`${val}_kuikaTableRef`] != null &&
      screen[`${val}_kuikaTableRef`].current != undefined &&
      screen[`${val}_kuikaTableRef`].current != null &&
      screen[`${val}_kuikaTableRef`].current.getSelectedRowData != undefined
    ) {
      const currentTableRef = screen[`${val}_kuikaTableRef`].current;

      const parentTableId = screen[`${val}_kuikaTableRef`].current?.getParentTableIdIfExist();
      const parentTableRef = screen[`${val.replace(currentTableRef.props.id, parentTableId)}_kuikaTableRef`];

      if (parentTableRef?.current) {
        const dataSourcePath = currentTableRef.props?.dataSourceOriginal?.match(
          /\[datafield:((?:(?!\[datafield:).)*)\]/
        );
        const dataSourcePathValue = dataSourcePath ? dataSourcePath[1] : "";

        const nestedTableRowIdx = parentTableRef.current.getNestedTableSelectedIndex(
          document.getElementById(parentTableId)
        );

        if (
          nestedTableRowIdx !== undefined &&
          parentTableRef.current.getSelectedRowData()[dataSourcePathValue] !== undefined &&
          parentTableRef.current.getSelectedRowData()[dataSourcePathValue][nestedTableRowIdx] !== undefined
        ) {
          return parentTableRef.current.getSelectedRowData()[dataSourcePathValue][nestedTableRowIdx][varName];
        }
      }

      if (varName.charAt(0) === ".") varName = varName.substr(1);
      if (varName === "k_rowIndex") {
        return screen[`${val}_kuikaTableRef`].current.getSelectedIndex();
      }
      if (varName === "k_columnIndex") {
        return screen[`${val}_kuikaTableRef`].current.getSelectedColumnIndex();
      }
      if (varName === "selectedValues") {
        return screen[`${val}_kuikaTableRef`].current.getSelectedValues();
      }
      return (
        screen[`${val}_kuikaTableRef`].current.getSelectedRowData() &&
        screen[`${val}_kuikaTableRef`].current.getSelectedRowData()[varName]
      );
    }

    if (
      varName != undefined &&
      screen[`${val}_kuikaKanbanBoardRef`] != undefined &&
      screen[`${val}_kuikaKanbanBoardRef`] != null &&
      screen[`${val}_kuikaKanbanBoardRef`].current != undefined &&
      screen[`${val}_kuikaKanbanBoardRef`].current != null
    ) {
      if (varName === "laneId" || targetField === "k_laneId") {
        return screen[`${val}_kuikaKanbanBoardRef`].current.getLaneId();
      }

      if (varName === "laneTitle" || targetField === "k_laneTitle") {
        return screen[`${val}_kuikaKanbanBoardRef`].current.getLaneTitle();
      }

      if (varName === "laneLabel" || targetField === "k_laneLabel") {
        return screen[`${val}_kuikaKanbanBoardRef`].current.getLaneLabel();
      }

      if (varName === "laneDroppable" || targetField === "k_laneDroppable") {
        return screen[`${val}_kuikaKanbanBoardRef`].current.getLaneDroppable();
      }

      if (varName === "laneIndex" || targetField === "k_laneIndex") {
        return screen[`${val}_kuikaKanbanBoardRef`].current.getLaneIndex();
      }

      if (varName === "laneTargetIndex" || targetField === "k_laneTargetIndex") {
        return screen[`${val}_kuikaKanbanBoardRef`].current.getLaneTargetIndex();
      }

      if (varName === "cardId" || targetField === "k_cardId") {
        return screen[`${val}_kuikaKanbanBoardRef`].current.getCardId();
      }

      if (varName === "cardLaneId" || targetField === "k_cardLaneId") {
        return screen[`${val}_kuikaKanbanBoardRef`].current.getCardLaneId();
      }

      if (varName === "cardTargetLaneId" || targetField === "k_cardTargetLaneId") {
        return screen[`${val}_kuikaKanbanBoardRef`].current.getCardTargetLaneId();
      }

      if (varName === "cardTitle" || targetField === "k_cardTitle") {
        return screen[`${val}_kuikaKanbanBoardRef`].current.getCardTitle();
      }

      if (varName === "cardLabel" || targetField === "k_cardLabel") {
        return screen[`${val}_kuikaKanbanBoardRef`].current.getCardLabel();
      }

      if (varName === "cardSubTitle" || targetField === "k_cardSubTitle") {
        return screen[`${val}_kuikaKanbanBoardRef`].current.getCardSubTitle();
      }

      if (varName === "cardDescription" || targetField === "k_cardDescription") {
        return screen[`${val}_kuikaKanbanBoardRef`].current.getCardDescription();
      }

      if (varName === "cardEscalationText" || targetField === "k_cardEscalationText") {
        return screen[`${val}_kuikaKanbanBoardRef`].current.getCardEscalationText();
      }

      if (varName === "cardDraggable" || targetField === "k_cardDraggable") {
        return screen[`${val}_kuikaKanbanBoardRef`].current.getCardDraggable();
      }

      if (varName === "cardIndex" || targetField === "k_cardIndex") {
        return screen[`${val}_kuikaKanbanBoardRef`].current.getCardIndex();
      }

      if (varName === "cardTargetIndex" || targetField === "k_cardTargetIndex") {
        return screen[`${val}_kuikaKanbanBoardRef`].current.getCardTargetIndex();
      }
    }

    if (
      varName != undefined &&
      screen[`${val}_kuikaGalleryViewRef`] != undefined &&
      screen[`${val}_kuikaGalleryViewRef`] != null &&
      screen[`${val}_kuikaGalleryViewRef`].current != undefined &&
      screen[`${val}_kuikaGalleryViewRef`].current != null &&
      screen[`${val}_kuikaGalleryViewRef`].current.getSelectedRowData != undefined
    ) {
      if (varName.charAt(0) === ".") varName = varName.substr(1);

      if (varName === "k_rowIndex") {
        return screen[`${val}_kuikaGalleryViewRef`].current.getSelectedIndex();
      }

      return (
        screen[`${val}_kuikaGalleryViewRef`].current.getSelectedRowData() &&
        screen[`${val}_kuikaGalleryViewRef`].current.getSelectedRowData()[varName]
      );
    }
    if (
      varName != undefined &&
      screen[`${val}_kuikaCarouselRef`] != undefined &&
      screen[`${val}_kuikaCarouselRef`] != null &&
      screen[`${val}_kuikaCarouselRef`].current != undefined &&
      screen[`${val}_kuikaCarouselRef`].current != null &&
      screen[`${val}_kuikaCarouselRef`].current.getSelectedRowData != undefined
    ) {
      if (varName.charAt(0) === ".") varName = varName.substr(1);

      if (varName === "k_rowIndex") {
        return screen[`${val}_kuikaCarouselRef`].current.getSelectedIndex();
      }

      return (
        screen[`${val}_kuikaCarouselRef`].current.getCarouselCurrentData() &&
        screen[`${val}_kuikaCarouselRef`].current.getCarouselCurrentData()[varName]
      );
    }
    if (
      varName != undefined &&
      screen[`${val}_kuikaFlexGridRef`] != undefined &&
      screen[`${val}_kuikaFlexGridRef`] != null &&
      screen[`${val}_kuikaFlexGridRef`].current != undefined &&
      screen[`${val}_kuikaFlexGridRef`].current != null &&
      screen[`${val}_kuikaFlexGridRef`].current.getSelectedRowData != undefined
    ) {
      if (varName.charAt(0) === ".") varName = varName.substr(1);

      if (varName === "k_rowIndex") {
        return screen[`${val}_kuikaFlexGridRef`].current.getSelectedIndex();
      }

      return (
        screen[`${val}_kuikaFlexGridRef`].current.getSelectedRowData() &&
        screen[`${val}_kuikaFlexGridRef`].current.getSelectedRowData()[varName]
      );
    }
    if (
      varName != undefined &&
      screen[`${val}_kuikaHorizontalTableRef`] != undefined &&
      screen[`${val}_kuikaHorizontalTableRef`] != null &&
      screen[`${val}_kuikaHorizontalTableRef`].current != undefined &&
      screen[`${val}_kuikaHorizontalTableRef`].current != null &&
      screen[`${val}_kuikaHorizontalTableRef`].current.getSelectedRowData != undefined
    ) {
      if (varName.charAt(0) === ".") varName = varName.substr(1);

      if (varName === "k_rowIndex") {
        return screen[`${val}_kuikaHorizontalTableRef`].current.getSelectedIndex();
      }

      return (
        screen[`${val}_kuikaHorizontalTableRef`].current.getSelectedRowData() &&
        screen[`${val}_kuikaHorizontalTableRef`].current.getSelectedRowData()[varName]
      );
    }
    if (
      varName != undefined &&
      screen[`${val}_kuikaPaginationRef`] != undefined &&
      screen[`${val}_kuikaPaginationRef`] != null
    ) {
      if (
        screen[`${val}_kuikaPaginationRef`].current != undefined &&
        screen[`${val}_kuikaPaginationRef`].current != null &&
        screen[`${val}_kuikaPaginationRef`].current.getCurrentData != undefined
      ) {
        if (varName.charAt(0) === ".") varName = varName.substr(1);
        return (
          screen[`${val}_kuikaPaginationRef`].current.getCurrentData() &&
          screen[`${val}_kuikaPaginationRef`].current.getCurrentData()[varName]
        );
      }
      if (varName === "current") return 1;
      return 10;
    }
    if (
      varName != undefined &&
      screen[`${val}_kuikaSelectBoxRef`] != undefined &&
      screen[`${val}_kuikaSelectBoxRef`] != null &&
      screen[`${val}_kuikaSelectBoxRef`].current != undefined &&
      screen[`${val}_kuikaSelectBoxRef`].current != null &&
      screen[`${val}_kuikaSelectBoxRef`].current.getSearchValue != undefined
    ) {
      if (targetField && targetField.charAt(0) === ".") targetField = targetField.substr(1);
      if (targetField === "k_searchValue") {
        return screen[`${val}_kuikaSelectBoxRef`].current.getSearchValue();
      }
    } else if (
      varName != undefined &&
      screen[`${val}_kuikaMultiSelectRef`] != undefined &&
      screen[`${val}_kuikaMultiSelectRef`] != null &&
      screen[`${val}_kuikaMultiSelectRef`].current != undefined &&
      screen[`${val}_kuikaMultiSelectRef`].current != null &&
      screen[`${val}_kuikaMultiSelectRef`].current.getSearchValue != undefined
    ) {
      if (targetField && targetField.charAt(0) === ".") targetField = targetField.substr(1);
      if (targetField === "k_searchValue") {
        return screen[`${val}_kuikaMultiSelectRef`].current.getSearchValue();
      }
    } else if (
      varName != undefined &&
      screen[`${val}_kuikaAutoCompleteRef`] != undefined &&
      screen[`${val}_kuikaAutoCompleteRef`] != null &&
      screen[`${val}_kuikaAutoCompleteRef`].current != undefined &&
      screen[`${val}_kuikaAutoCompleteRef`].current != null &&
      screen[`${val}_kuikaAutoCompleteRef`].current.getSearchValue != undefined
    ) {
      if (targetField && targetField.charAt(0) === ".") targetField = targetField.substr(1);
      if (targetField === "k_searchValue") {
        return screen[`${val}_kuikaAutoCompleteRef`].current.getSearchValue();
      }
    } else if (
      varName != undefined &&
      screen[`${val}_kuikaGoogleMapsRef`] != undefined &&
      screen[`${val}_kuikaGoogleMapsRef`] != null &&
      screen[`${val}_kuikaGoogleMapsRef`].current != undefined &&
      screen[`${val}_kuikaGoogleMapsRef`].current != null &&
      screen[`${val}_kuikaGoogleMapsRef`].current.getLatValue != undefined
    ) {
      if (targetField && targetField.charAt(0) === ".") targetField = targetField.substr(1);
      if (varName === "Latitude") {
        return screen[`${val}_kuikaGoogleMapsRef`].current.getLatValue();
      }
      if (varName === "Longitude") {
        return screen[`${val}_kuikaGoogleMapsRef`].current.getLngValue();
      }
      if (varName.charAt(0) === ".") varName = varName.substr(1);
      return (
        screen[`${val}_kuikaGoogleMapsRef`].current.getSelectedRowData() &&
        screen[`${val}_kuikaGoogleMapsRef`].current.getSelectedRowData()[varName]
      );
    } else if (
      varName != undefined &&
      screen[`${val}_kuikaChatRef`] != undefined &&
      screen[`${val}_kuikaChatRef`] != null &&
      screen[`${val}_kuikaChatRef`].current != undefined &&
      screen[`${val}_kuikaChatRef`].current != null &&
      screen[`${val}_kuikaChatRef`].current.getMessage != undefined &&
      screen[`${val}_kuikaChatRef`].current.getCurrentlyEditingMessageId != undefined
    ) {
      if (varName === "message" || targetField === "k_message") {
        return screen[`${val}_kuikaChatRef`].current.getMessage();
      }

      if (varName === "currentlyEditingMessageId" || targetField === "k_currentlyEditingMessageId") {
        return screen[`${val}_kuikaChatRef`].current.getCurrentlyEditingMessageId();
      }

      if (varName === "messageIdToBeDeleted" || targetField === "k_messageIdToBeDeleted") {
        return screen[`${val}_kuikaChatRef`].current.messageIdToBeDeleted;
      }
    } else if (
      varName != undefined &&
      screen[`${val}_kuikaPlacesAutocompleteInputRef`] != undefined &&
      screen[`${val}_kuikaPlacesAutocompleteInputRef`] != null &&
      screen[`${val}_kuikaPlacesAutocompleteInputRef`].current != undefined &&
      screen[`${val}_kuikaPlacesAutocompleteInputRef`].current != null &&
      screen[`${val}_kuikaPlacesAutocompleteInputRef`].current.getLatitude != undefined
    ) {
      if (varName === "Latitude" || targetField === "k_latitude") {
        return screen[`${val}_kuikaPlacesAutocompleteInputRef`].current.getLatitude();
      }
      if (varName === "Longitude" || targetField === "k_longitude") {
        return screen[`${val}_kuikaPlacesAutocompleteInputRef`].current.getLongitude();
      }
      if (varName === "Address" || targetField === "k_address") {
        return screen[`${val}_kuikaPlacesAutocompleteInputRef`].current.getAddress();
      }
    } else if (
      varName != undefined &&
      screen[`${val}_kuikaLocationPickerComponentRef`] &&
      screen[`${val}_kuikaLocationPickerComponentRef`] != undefined &&
      screen[`${val}_kuikaLocationPickerComponentRef`] != null &&
      screen[`${val}_kuikaLocationPickerComponentRef`].current != undefined &&
      screen[`${val}_kuikaLocationPickerComponentRef`].current != null &&
      screen[`${val}_kuikaLocationPickerComponentRef`].current.getLatitude != undefined
    ) {
      if (varName === "Latitude" || targetField === "k_latitude") {
        return screen[`${val}_kuikaLocationPickerComponentRef`].current.getLatitude();
      }
      if (varName === "Longitude" || targetField === "k_longitude") {
        return screen[`${val}_kuikaLocationPickerComponentRef`].current.getLongitude();
      }
    } else if (
      varName != undefined &&
      screen[`${val}_kuikaStepperComponentRef`] &&
      screen[`${val}_kuikaStepperComponentRef`] != undefined &&
      screen[`${val}_kuikaStepperComponentRef`] != null &&
      screen[`${val}_kuikaStepperComponentRef`].current != undefined &&
      screen[`${val}_kuikaStepperComponentRef`].current != null &&
      screen[`${val}_kuikaStepperComponentRef`].current.getIndex != undefined
    ) {
      let index = screen[`${val}_kuikaStepperComponentRef`].current.getIndex();
      if (index === null || index === undefined || index < 0) return;

      return index + 1;
    } else if (
      screen[`${val}_kuikaWebViewRef`] &&
      screen[`${val}_kuikaWebViewRef`] != undefined &&
      screen[`${val}_kuikaWebViewRef`] != null &&
      screen[`${val}_kuikaWebViewRef`].current != undefined &&
      screen[`${val}_kuikaWebViewRef`].current != null &&
      screen[`${val}_kuikaWebViewRef`].current.getUrl != undefined
    ) {
      if (varName === "url" || targetField === "k_url") {
        return screen[`${val}_kuikaWebViewRef`].current.getUrl();
      }
    } else if (
      screen?.[`${val}_kuikaCalendarRef`]?.current != undefined &&
      screen?.[`${val}_kuikaCalendarRef`]?.current != null
    ) {
      if (varName === "currentDate" || varName === "k_currentDate" || targetField === "k_currentDate") {
        return screen[`${val}_kuikaCalendarRef`].current?.getCurrentDate?.();
      }

      if (varName === "currentDay" || varName === "k_currentDay" || targetField === "k_currentDay") {
        return screen[`${val}_kuikaCalendarRef`].current.getCurrentDay?.();
      }

      if (varName === "currentMonth" || varName === "k_currentMonth" || targetField === "k_currentMonth") {
        return screen[`${val}_kuikaCalendarRef`].current.getCurrentMonth?.();
      }

      if (varName === "currentYear" || varName === "k_currentYear" || targetField === "k_currentYear") {
        return screen[`${val}_kuikaCalendarRef`].current.getCurrentYear?.();
      }

      if (
        varName === "k_eventId" ||
        varName === "k_currentEvent.k_eventId" ||
        targetField === "k_eventId" ||
        targetField === "k_currentEvent.k_eventId"
      ) {
        return screen[`${val}_kuikaCalendarRef`].current.getCurrentEvent?.()?.id;
      }

      if (
        varName === "k_eventTitle" ||
        varName === "k_currentEvent.k_eventTitle" ||
        targetField === "k_eventTitle" ||
        targetField === "k_currentEvent.k_eventTitle"
      ) {
        return screen[`${val}_kuikaCalendarRef`].current.getCurrentEvent?.()?.title;
      }

      if (
        varName === "k_eventStartDate" ||
        varName === "k_currentEvent.k_eventStartDate" ||
        targetField === "k_eventStartDate" ||
        targetField === "k_currentEvent.k_eventStartDate"
      ) {
        return screen[`${val}_kuikaCalendarRef`].current.getCurrentEvent?.()?.start;
      }

      if (
        varName === "k_eventEndDate" ||
        varName === "k_currentEvent.k_eventEndDate" ||
        targetField === "k_eventEndDate" ||
        targetField === "k_currentEvent.k_eventEndDate"
      ) {
        return screen[`${val}_kuikaCalendarRef`].current.getCurrentEvent?.()?.end;
      }

      if (
        varName === "k_eventColor" ||
        varName === "k_currentEvent.k_eventColor" ||
        targetField === "k_eventColor" ||
        targetField === "k_currentEvent.k_eventColor"
      ) {
        return screen[`${val}_kuikaCalendarRef`].current.getCurrentEvent?.()?.backgroundColor;
      }

      return screen[`${val}_kuikaCalendarRef`].current.getCurrentEvent?.()?.[varName?.toLowerCase()];
    } else if (
      screen[`${val}_kuikaStopwatchRef`] &&
      screen[`${val}_kuikaStopwatchRef`] != undefined &&
      screen[`${val}_kuikaStopwatchRef`] != null &&
      screen[`${val}_kuikaStopwatchRef`].current != undefined &&
      screen[`${val}_kuikaStopwatchRef`].current != null &&
      screen[`${val}_kuikaStopwatchRef`].current.getValue != undefined
    ) {
      return screen[`${val}_kuikaStopwatchRef`].current.getValue();
    } else if (varName === undefined) return val;

    const formVal = screen.props.form.getFieldValue(val);
    if (val.endsWith("_value") && varName == "checked") return formVal;
    if (formVal != null && val.endsWith("_value") && varName !== "value") {
      // Multiple value component
      return formVal[varName];
    }
    if (formVal != null && val.endsWith("_selectedkey") && varName == "selectedKey") {
      // Menu Component Case!
      return formVal[varName];
    }

    return formVal;
  }

  public static dateIsWeekDay = (date: string | number | Date) => {
    if (!date) return false;
    return moment(date).isoWeekday() <= 5;
  };

  public static dateGetDay = (date: any) => {
    if (!date) return 0;
    return moment(date).date();
  };

  public static dateGetMonth = (date) => {
    if (!date) return 0;
    return moment(date).month() + 1;
  };

  public static dateGetYear = (date) => {
    if (!date) return 0;
    return moment(date).year();
  };

  public static toString = (_screen: any, val: any) => {
    if (val === null || val === undefined) return ""; // false değeri için böyle kalmalı
    if (Array.isArray(val)) {
      const returnValue: any[] = [];
      for (let i = 0; i < val.length; i++) {
        const vi: any = val[i];
        returnValue.push(vi.toString());
      }
      return returnValue;
    }
    return val.toString();
  };

  public static toBoolean = (_screen: any, val: any) => {
    if (!val) return false;
    if (val instanceof Boolean) return val;
    if (val instanceof String) {
      val = val.toLowerCase();
    }
    switch (val) {
      case true:
      case "true":
      case "on":
      case "yes":
      case 1:
        return true;
      default:
        return false;
    }
  };

  public static toDecimal = (_screen: any, val: any) => {
    if (!val) return val;

    if (val instanceof Date) {
      return val.getTime();
    }

    if (val instanceof moment) {
      return val.valueOf();
    }

    if ((parseFloat(val).toString() === "NaN") === true) {
      return val;
    }

    return parseFloat(val);
  };

  public static toDate(_screen: any, value: any) {
    if (!value) return null;

    if (value instanceof moment) return value;

    const dateFormat = this.getFormat(value);
    if (dateFormat === null) return null;
    return moment(value, dateFormat);
  }

  private static getDateFormat() {
    return {
      kuika_hhmm: "HH:mm",
      kuika_hhmmss: "HH:mm:ss",
      kuika_ddmmyyyy: "DD.MM.YYYY",
      kuika_ddmmyyyyhh: "DD.MM.YYYY HH",
      kuika_ddmmyyyyhhmm: "DD.MM.YYYY HH:mm",
      kuika_ddmmyyyyhhmmss: "DD.MM.YYYY HH:mm:ss",
      iso_int: "YYYY-MM-DD",
      short_date: "DD/MM/YYYY",
      iso_date_time: "YYYY-MM-DDTHH:mm:ss",
      iso_date_time_utc: "YYYY-MM-DDTHH:mm:ssZ"
    };
  }

  private static getFormat(d: moment.MomentInput) {
    const dateFormats = this.getDateFormat();
    for (const prop in dateFormats) {
      if (moment(d, dateFormats[prop], true).isValid()) {
        return dateFormats[prop];
      }
    }
    return null;
  }

  public static toInteger = (screen: any, val: any, varName?: string) => {
    if (!val) return val;
    if (varName && varName.length) {
      return this.toInteger(screen, this.value(screen, val, varName));
    }
    if (val === "") return val;
    if (val === true) return 1;
    if (val === false) return 0;
    if (val === "true") return 1;
    if (val === "false") return 0;
    if (val === "True") return 1;
    if (val === "False") return 0;
    if ((parseInt(val).toString() === "NaN") === true) {
      return val;
    }
    return parseInt(val);
  };

  public static toDouble = (screen: any, val: any, varName?: string) => {
    if (!val) return val;
    if (varName && varName.length) {
      return this.toDouble(screen, this.value(screen, val, varName));
    }
    if (val === "") return val;
    if (val === true) return 1;
    if (val === false) return 0;
    if (val === "true") return 1;
    if (val === "false") return 0;
    if (val === "True") return 1;
    if (val === "False") return 0;
    if ((parseFloat(val).toString() === "NaN") === true) {
      return val;
    }
    return parseFloat(val);
  };

  // public static async claimTask(screen: any, StepKey: string) {
  //   const userId = screen.props?.context?.userInfo?.id;
  //   if (userId !== undefined) {
  //     const result = (await WorkflowService.AssignTask(StepKey, userId)).data;
  //     // if (result) this.navigateToTask(screen, StepKey);
  //   }
  // }

  // public static async assignTask(_screen: any, StepKey: string, user: string) {
  //   if (user !== undefined) {
  //     const result = (await WorkflowService.AssignTask(StepKey, user)).data;
  //   }
  // }

  // public static async releaseTask(_screen: any, StepKey: string) {
  //   const result = (await WorkflowService.ReleaseTask(StepKey)).data;
  // }

  public static toGuid(_screen: any, val: string) {
    if (!val) return null;
    if (!Guid.isGuid(val)) return null;

    return Guid.parse(val);
  }

  public static async navigateToTask(screen: any, StepKey: string) {
    const stepInfo = (await WorkflowService.GetNavigationParameters(StepKey)).data;
    if (stepInfo !== undefined && stepInfo.assignedUserId !== undefined) {
      const screenName = (await WorkflowService.GetStepScreen(stepInfo.processId, stepInfo.stepId, stepInfo.appId))
        .data;
      if (screenName !== undefined && screenName !== "") {
        const navigationScreenInputs = {} as any;
        navigationScreenInputs.workflowStepInstanceId = StepKey;

        let screenInputsQuery = "";
        screenInputsQuery = querystringify.stringify(navigationScreenInputs, true);
        if (screenName.includes("#")) {
          const pathLastSlash = window.location.href.lastIndexOf("/");
          const originWithExtras = window.location.href.slice(0, pathLastSlash);
          const newPath = `${originWithExtras}/${screenName}${screenInputsQuery}`;
          // TODO: Also push to history before redirect??
          window.location.replace(newPath);
        } else {
          const { history } = screen.props.navigator;
          history.push({
            pathname: screenName,
            search: screenInputsQuery,
            state: {
              screenInputs: {
                // TODO
              }
            }
          });
        }
      } else if (stepInfo.webViewUrl && stepInfo.webViewUrl !== "") {
        window.location.replace(stepInfo.webViewUrl);
      }
    }
  }

  public static async loadWorkflowStepInstanceParameters(screen: any, StepKey: string) {
    const stepdata = (await WorkflowService.GetTaskInstanceParameters(StepKey)).data;
    const userId = window?.userInfo?.id;
    if (stepdata !== undefined) {
      if (stepdata?.redirect === true) {
        this.notify(this, stepdata.redirectMessage, "warning", "bottom-right", 3, null, null, null);
        screen.props.navigator.gotoStartingScreen();
      }
      return stepdata;
    }
    return undefined;
  }

  public static getProcessParameterValue(form: any, screenName, diId, propertyName) {
    if (form) {
      const value = form.getFieldValue(`${screenName}_${diId}_${propertyName}`);
      if (value && value !== "") return value;
      return "";
    }
    return "";
  }

  public static getStepDataValue(bag: ITaskDataParameters, key: string) {
    if (bag !== undefined) {
      if (key == "StepInstanceId") return bag.workflowStepInstanceId;
      if (key == "ProcessInstanceId") return bag.processInstance;
      if (bag.stepData && bag.stepData.length > 0) {
        const data = bag.stepData.find((b) => b.key === key);
        if (data !== undefined) return data.value;
      }
    }
    return "";
  }

  public static postWebKitMessage(scope: any, messageBody) {
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.iosKuikaListener)
      window.webkit.messageHandlers.iosKuikaListener.postMessage(messageBody); // {"actionType":"CLOSE_WEBVIEW"}
    else if (window.AndroidKuikaListener) window.AndroidKuikaListener.postMessage(JSON.stringify(messageBody));
    else console.log("Mobile message handlers not supported by browser");
  }

  // obsolete
  // public static getStepScreenId = (processId, stepId): string => {
  //   const cnf = ProcessConfig.processScreens as IProcessConfigData[];
  //   const screenConfig = cnf.find((s) => s.processId == processId && s.stepId === stepId);
  //   if (screenConfig !== undefined) return screenConfig.screenId;
  //   return undefined;
  // };

  public static getScreenNameById = (screenId: string): string => {
    const screenList = this.getScreenList();
    const screenName = getDictionaryValueByKey(screenList, screenId);
    return screenName;
  };

  public static getScreenNameByActiveUserRoles = (): string => {
    const roleStartingScreenIdList = this.getRoleStartingScreenIdList();
    const userRoles = window?.userInfo?.roles;
    let result = "";
    userRoles?.forEach((role: { name: string }) => {
      const screenId = getDictionaryValueByKey(roleStartingScreenIdList, role?.name);
      if (screenId !== undefined) {
        const startingScreenName = this.getScreenNameById(screenId);
        if (startingScreenName !== undefined && startingScreenName !== "") {
          result = startingScreenName;
        }
      }
    });
    return result;
  };

  public static getScreenList = () => {
    return [ { key: "e29fbb19-71f2-4341-8610-05b734f62ab4", value: "Login"}, { key: "dce790a5-4354-4f3b-88e6-3479d49c137d", value: "ForgotPassword"}, { key: "55248f36-d1c7-4b73-9900-38de757b2676", value: "Profil"}, { key: "9d414fc8-aee7-41f7-9bee-3f226536ec3b", value: "VerificationCode"}, { key: "ba4691e1-0277-4fc5-82bb-4352336e316a", value: "Signincopy"}, { key: "746a3f21-601d-4c2c-9a73-4bb1362ad10e", value: "HomePage"}, { key: "450d0377-46f2-4d74-9f4f-5a5423b0bdc1", value: "FasonTakipLayout"}, { key: "f2d77b1c-c4e0-42f8-b0e6-70ea5435d8e5", value: "Marketing_Dashboard_With_Left_Menu"}, { key: "c6a5dd9e-9589-4c6d-86a4-7cccce408fbf", value: "Signup"}, { key: "49d077ba-0435-4cdf-8b53-83b590a3e416", value: "ResetPassword"}, { key: "aa34dcf0-40e7-4de0-a4fc-9d04c35542a5", value: "Ayarlar"}, { key: "366167ea-1553-4546-b008-c22c6ca9279f", value: "Sales_Dashboard_With_Left_Menu"}, { key: "220d24ad-52b5-4f34-ad00-d5372aff0b07", value: "LeftMenu"}, { key: "1848dfd7-7cb1-4a58-a86c-e261849b1a30", value: "ResimDetay"}, { key: "9e780e63-a194-477b-844a-e603d6646acf", value: "Tedarikcitanimlama"},];
  };

  public static getRoleStartingScreenIdList = () => {
    return [];
  };

  // Navigation
  // 0: {name: "fromScreenId", Typename: "String", cardinality: 1, isMandatory: false, ownerClassName: null, …}
  // 1: {name: "toScreenId", Typename: "String", cardinality: 1, isMandatory: false, ownerClassName: null, …}
  // 2: {name: "toUrl", Typename: "String", cardinality: 1, isMandatory: false, ownerClassName: null, …}
  // 3: {name: "openAs", Typename: "String", cardinality: 1, isMandatory: false, ownerClassName: null, …}
  // 4: {name: "navigationKuikaId", Typename: "String", cardinality: 1, isMandatory: false, ownerClassName: null, …}
  public static navigate(
    screen: any,
    _callback: any,
    fromScreen: string,
    toScreen: string,
    _toUrl: string,
    openAs: string | null,
    navigationKuikaId: string | null,
    _title: string | null,
    _placement: string | null,
    _closable: string | null,
    _width: string | null,
    _height: string | null,
    executePageInitActionsAfterClose: boolean
  ): string {
    const { history } = screen.props.navigator;

    let screenInputsQuery = "";
    if (window.kuika?.navigationScreenInputs && !_.isEmpty(window.kuika?.navigationScreenInputs)) {
      screenInputsQuery = querystringify.stringify(window.kuika?.navigationScreenInputs, true);
    }

    if (!window.kuika) window.kuika = {};

    if ((screen.props.navigator as any)?.history?.location?.search?.includes("redirectOnMobile=true")) {
      this.postWebKitMessage(null, {
        actionType: "NAVIGATE",
        openAs: `${openAs}`,
        width: `${_width}`,
        height: `${_height}`,
        placement: `${_placement}`,
        toScreen: `${toScreen}`,
        triggerPageInit: `${executePageInitActionsAfterClose}`,
        screenInputs: `${screenInputsQuery}`
      });
      return "";
    }

    switch (openAs) {
      case "currentPage":
        history.push({
          pathname: toScreen,
          search: screenInputsQuery,
          state: {
            screenInputs: {
              // TODO
            }
          }
        });

        if (fromScreen === toScreen || toScreen === window.kuika?.navigationComponentMasterScreen) {
          window.location.reload();
        }
        // delete window.kuika?.navigationComponentMasterScreen;

        if (!window.kuika?.pageInitCallers) window.kuika.pageInitCallers = {};
        window.kuika.pageInitCallers[fromScreen] = executePageInitActionsAfterClose;

        break;

      case "newTab":
      case "newWindow":
        HelperService.redirectToPath(`/${toScreen}${screenInputsQuery}`, { openInNewTab: true });
        break;

      default:
        if (!window.kuika?.navigationComponentMasterScreen) window.kuika.navigationComponentMasterScreen = fromScreen;
        else if (
          window.kuika.navigationComponentMasterScreen &&
          fromScreen !== window.kuika.navigationComponentMasterScreen
        ) {
          window.kuika.navigationComponentMasterScreen = fromScreen;
        }
        const obj = {};
        obj[`NavVisible${navigationKuikaId}`] = true;
        screen.setState(obj);
        break;
    }

    return "";
  }

  // GoBack
  public static goBack(screen: { props: { onClose: () => void; navigator: { goBack: () => void } } }) {
    if (screen?.props?.onClose) {
      KuikaAppManager.hideSpinner(screen);
      screen.props.onClose();
      return;
    }
    if (screen.props.navigator.goBack) {
      if ((screen.props.navigator as any)?.history?.location?.search?.includes("redirectOnMobile=true")) {
        this.postWebKitMessage(null, { actionType: "GO_BACK" });
      } else {
        screen.props.navigator.goBack();
      }
    }
  }

  public static getMyUsername(): string {
    return window?.userInfo?.username;
  }

  public static async logout(): Promise<void> {
    await UserService.Logout();
  }

  public static async signinwithMSAL(): Promise<void> {
    sessionStorage.setItem("msalRedirectionStarted", "loaded");
    HelperService.gotoLogin();
  }

  // Notification
  // sendPushNotificationToUsername: _sendPushNotificationToUsername,
  // sendPushNotificationToApp: _sendPushNotificationToApp,
  public static notify(
    screen: any,
    message: string,
    type: "success" | "danger" | "info" | "default" | "warning" | "custom",
    position:
      | "top-left"
      | "top-right"
      | "top-center"
      | "bottom-left"
      | "bottom-right"
      | "bottom-center"
      | "top"
      | "bottom",
    duration: number,
    backgroundColor: string | null,
    textColor: string | null,
    fontSize: number | null
  ): boolean | undefined {
    if (message === undefined || message === null) message = "";
    if (typeof message === "object") message = JSON.stringify(message);
    else if (typeof message !== "string" && (message as any).toString) message = (message as any).toString();
    if (message) {
      // todo message = ReactSystemFunctions.translateCustomActions(screen.ml, "{actionID}", screen.this.defaultML, message);

      if (duration === null || duration === undefined || duration < 0) {
        switch (type) {
          case "success": {
            duration = KMainFunctions.NOTIFY_ACTION_DEFAULT_SUCCESS_DURATION;
            break;
          }
          case "danger": {
            duration = KMainFunctions.NOTIFY_ACTION_DEFAULT_ERROR_DURATION;
            break;
          }
          case "info": {
            duration = KMainFunctions.NOTIFY_ACTION_DEFAULT_INFO_DURATION;
            break;
          }
          case "warning": {
            duration = KMainFunctions.NOTIFY_ACTION_DEFAULT_WARNING_DURATION;
            break;
          }
          case "default":
          default: {
            duration = KMainFunctions.NOTIFY_ACTION_DEFAULT_DEFAULT_DURATION;
            break;
          }
        }
      }
      if (position === "top") position = "top-center";
      if (position === "bottom") position = "bottom-center";

      const instanceId = Guid.create().toString();

      let customStyle: string | undefined;

      if (type === "custom") {
        type = "default";

        const style = document.getElementById("dynamic_style");

        customStyle = `
          .custom-notification-${instanceId} {
            background-color: ${backgroundColor} !important;
            border-left: unset !important;

            .notification__close {
              opacity: 0 !important;
            }

            .notification__message {
              color: ${textColor} !important;
              font-size: ${fontSize}px !important;
            }
          }
        `
          .replace(" ", "")
          .trim()
          .replace(/(\r\n|\n|\r)/gm, "");

        style.innerHTML += customStyle;
      }

      setTimeout(() => {
        if (duration < 0) duration = 0;
        if (position === "top") position = "top-center";
        if (position === "bottom") position = "bottom-center";
        if (type === "custom") {
          type = "default";

          setTimeout(() => {
            const style = document.getElementById("dynamic_style");
            style.innerHTML = style.innerHTML.replace(customStyle, "");
          }, duration * 1000 + 1000);
        }

        store.addNotification({
          title: "",
          message,
          type,
          container: position,
          animationIn: [`custom-notification-${instanceId}`, "animated", "flipInX"],
          animationOut: [`custom-notification-${instanceId}`, "animated", "fadeOut"],
          dismiss: {
            duration: duration * 1000,
            pauseOnHover: true,
            showIcon: true
          }
        });
      }, 150);
      return true;
    }
  }

  // Device

  public static printCurrentScreen() {}

  public static getPhotoFromGallery = () =>
    new Promise((resolve, reject) => {
      let listener = false;
      const input = document.createElement("input");
      input.classList.add("kuika-take-photo-gallery");
      input.setAttribute("type", "file");
      input.setAttribute("name", "kuika_photo_take");
      input.style.display = "none";
      input.accept = "image/*,application/pdf";
      listener = true;

      window.addEventListener("focus", () => {
        setTimeout(() => {
          if (listener) {
            listener = false;
            let files = Array.from(input.files);
            if (files.length === 0) {
              resolve("");
            }
          }
        }, 1000);
      });

      input.onchange = () => {
        let files = Array.from(input.files);
        if (files.length === 0) {
          resolve("");
        }

        const [file] = files;

        const reader = new FileReader();
        reader.addEventListener("load", (e) => {
          resolve(e.target.result);
        });
        reader.addEventListener("error", (e) => {
          reject(e.target.error);
        });

        reader.readAsDataURL(file);
      };

      input.click();
    });

  public static uploadFile = () => {
    return new Promise((resolve) => {
      let input = document.createElement("input");
      input.type = "file";
      input.multiple = false;
      input.accept = "*";

      input.onchange = () => {
        let files = Array.from(input.files);
        if (files.length === 0) {
          resolve("");
        }

        const [file] = files;

        const reader = new FileReader();
        reader.addEventListener("load", (e) => {
          resolve(e.target.result);
        });
        reader.addEventListener("error", (e) => {
          KMainFunctions.exceptionHandler(e);
          resolve({});
        });
        reader.readAsDataURL(file);
      };

      input.oncancel = () => {
        resolve({});
      };

      input.click();
    });
  };

  public static uploadFileWithInfo = () => {
    return new Promise((resolve) => {
      let input = document.createElement("input");
      input.type = "file";
      input.multiple = false;
      input.accept = "*";

      input.onchange = () => {
        let files = Array.from(input.files);
        if (files.length === 0) {
          resolve({
            data: {}
          });
        }

        const [file] = files;
        var resultObj = {
          data: {}
        } as any;
        resultObj.data.name = file.name;
        resultObj.data.type = file.type;
        resultObj.data.size = file.size;
        const reader = new FileReader();
        reader.addEventListener("load", (e) => {
          resultObj.data.data = e.target.result;
          resolve(resultObj);
        });
        reader.addEventListener("error", (e) => {
          KMainFunctions.exceptionHandler(e);
          resolve({});
        });
        reader.readAsDataURL(file);
      };

      input.oncancel = () => {
        resolve({});
      };

      input.click();
    });
  };

  public static takePhotoKuikaFunction = (comp: {
    props: { showPhotoTake: () => void; hidePhotoTake: () => void };
    forceUpdate: () => void;
  }) =>
    new Promise((resolve, _reject) => {
      comp.props.showPhotoTake();
      setTimeout(() => {
        const imgNode: HTMLImageElement = document
          ?.getElementsByClassName("react-html5-camera-photo")[0]
          ?.getElementsByTagName("img")[0];
        if (imgNode) {
          imgNode.addEventListener("load", () => {
            comp.props.hidePhotoTake();
            comp.forceUpdate();
            resolve(imgNode.src);
          });
        }
        const myInterval = setInterval(() => {
          const imgNode: HTMLCollectionOf<Element> = document.getElementsByClassName("react-html5-camera-photo");
          if (!imgNode.length) {
            clearInterval(myInterval);
            resolve("");
          }
        }, 1000);
      }, 350);
    });

  // openInBrowser: _openInBrowser,
  public static openInBrowser(val: unknown, openAs: "current" | "newtab") {
    if (typeof val !== "string") {
      KMainFunctions.displayErrorNotification("Invalid URL");
      return;
    }

    let url: string = val;

    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      url = `https://${url}`;
    }

    if (!KMainFunctions.getIsValidHttpUrl(url)) {
      KMainFunctions.displayErrorNotification(`Invalid URL: "${url}"`);
      return;
    }

    if (openAs === "newtab") {
      setTimeout(() => window.open(url, "_blank").focus(), 100);
    } else {
      setTimeout(() => window.open(url, "_self").focus(), 100);
    }
  }

  public static openInWebView(val: string) {
    let url: string = val;
    if (url === undefined || url === null || url === "") {
      window.open("", "_blank").focus();
      return;
    }

    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      url = `https://${url}`;
    }

    setTimeout(() => window.open(url, "_blank").focus(), 100);
  }

  public static printPdf(base64: string) {
    let base64WithoutPrefix = base64;
    if (base64.startsWith("data:application/pdf;base64,")) {
      base64WithoutPrefix = base64.substring("data:application/pdf;base64,".length);
    }
    printJS({ printable: base64WithoutPrefix, type: "pdf", base64: true });
  }

  public static printExcel(base64: any) {
    console.log("TODO: SYSTEM FUNCTION NOT IMPLEMENTED YET: printExcel");
    console.log(base64);
  }

  // Local Storage
  // addToBag: _addToBag,
  // removeFromBag: _removeFromBag,
  // clearBag: _clearBag,

  // Location

  public static async locationCoordinatesOfAdress(address: string): Promise<any> {
    if (address === undefined || address === "") {
      return { data: { latitude: "0", longitude: "0" } };
    }
    if (!this.isDocumentReady()) {
      return new Promise((resolve) => {
        setTimeout(async () => {
          const res = await this.locationCoordinatesOfAdress(address);
          resolve(res);
        }, 100);
      });
    }

    const geocoder = new google.maps.Geocoder();

    try {
      const response = await geocoder.geocode({ address });
      if (response?.results.length > 0) {
        const lat = response.results[0].geometry?.location?.lat();
        const lng = response.results[0].geometry?.location?.lng();
        const result = { data: { latitude: lat, longitude: lng } };
        return result;
      }
    } catch (error) {
      KMainFunctions.exceptionHandler(error);
      return { data: { latitude: undefined, longitude: undefined } };
    }
  }

  public static async locationAdressOfCoordinates(latitude: any, longitude: any): Promise<any> {
    if (latitude === undefined || latitude === "" || longitude === undefined || latitude === "") {
      return { data: { address: "", city: "", state: "", country: "" } };
    }
    if (!this.isDocumentReady()) {
      return new Promise((resolve) => {
        setTimeout(async () => {
          const res = await this.locationAdressOfCoordinates(latitude, longitude);
          resolve(res);
        }, 100);
      });
    }

    const geocoder = new google.maps.Geocoder();

    try {
      const response = await geocoder.geocode({ location: { lat: Number(latitude), lng: Number(longitude) } });

      let city: any;
      let state: any;
      let country: any;
      let address: any;

      const r = response.results[0];
      if (r) {
        address = r.formatted_address;
        const { address_components } = r;
        if (address_components?.length > 0) {
          for (let i = 0; i < address_components.length; i++) {
            if (address_components[i]?.types?.length > 0) {
              for (let j = 0; j < address_components[i].types.length; j++) {
                if (address_components[i]?.types) {
                  switch (address_components[i].types[j]) {
                    case "administrative_area_level_1":
                      city = address_components[i].long_name;
                      break;
                    case "administrative_area_level_2":
                      state = address_components[i].long_name;
                      break;
                    case "country":
                      country = address_components[i].long_name;
                      break;
                  }
                }
              }
            }
          }
        }
      }
      const result = { data: { address, city, state, country } };
      return result;
    } catch (error) {
      KMainFunctions.exceptionHandler(error);
      return { data: { address: undefined, city: undefined, state: undefined, country: undefined } };
    }
  }

  public static async locationGetCurrentCoordinates(): Promise<any> {
    try {
      const coordinates = await this.locationGetCurrentCoordinatesPromise();
      const result = {
        data: {
          accuracy: coordinates?.coords?.accuracy,
          latitude: coordinates?.coords?.latitude,
          longitude: coordinates?.coords?.longitude
        }
      };
      return result;
    } catch (error) {
      KMainFunctions.exceptionHandler(error);
      return { data: { accuracy: undefined, latitude: undefined, longitude: undefined } };
    }
  }

  static async locationGetCurrentCoordinatesPromise(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, {
        enableHighAccuracy: true, // Use high-accuracy mode, if available
        timeout: 10000, // Set a timeout of 10 seconds
        maximumAge: 0 // Force a fresh location
      });
    });
  }

  // UI
  public static changeVisibilityOf(screen: any, stateName: string, isVisible: string, _arg: string = "") {
    const obj = {};
    obj[stateName] = isVisible;
    screen.setState(obj);
  }

  public static toggleVisibilityOf(screen: any, stateName: string) {
    const obj = {};
    let isVisible = screen.state[stateName];
    if (isVisible === "hidden") {
      isVisible = "visible";
    } else {
      isVisible = "hidden";
    }
    obj[stateName] = isVisible;
    screen.setState(obj);
  }

  public static toggleEditabilityOf(screen: any, stateName: string) {
    const obj = {};
    let isVisible = screen.state[stateName];
    if (isVisible === "disabled") {
      isVisible = "enabled";
    } else {
      isVisible = "disabled";
    }
    obj[stateName] = isVisible;
    screen.setState(obj);
  }

  public static changeEnabledOf(screen: any, stateName: string, isEnabled: string) {
    const obj = {};
    obj[stateName] = isEnabled;
    screen.setState(obj);
  }

  public static hideIsNotAuthorized(screen: any, stateName: string, roleIds: string[]) {
    const obj = {} as any;
    let isVisible = "hidden";
    const { userInfo } = screen.props.context;
    if (userInfo == null || userInfo == undefined) return isVisible;
    const role =
      userInfo.roles &&
      userInfo.roles.find((r: { id: string; name: string }) => roleIds.find((p) => p === r.id || p === r.name));
    if (role || roleIds.length == 0) {
      isVisible = "visible";
    }
    obj[stateName] = isVisible;
    screen.setState(obj);
  }

  public static sendWhatsappMessage(number: string, message: string) {
    const urlParams = new URLSearchParams(window.location.search);
    const redirectOnMobileValue = urlParams.get("redirectOnMobile");

    if (redirectOnMobileValue === "true") {
      this.postWebKitMessage(null, {
        actionType: "SEND_WP_MESSAGE",
        number: `${number}`,
        message: `${message}`
      });
    } else {
      window.open(`https://wa.me/${number}?text=${encodeURIComponent(message)}`);
    }
  }

  public static disableIsNotAuthorized(screen: any, stateName: string, roleIds: string[]) {
    const obj = {} as any;
    let isEnabled = "disabled";
    const { userInfo } = screen.props.context;
    const role = userInfo?.roles?.find((r: { id: string; name: string }) =>
      roleIds.find((p) => p === r.id || p === r.name)
    );
    if (role) {
      isEnabled = "enabled";
    }
    obj[stateName] = isEnabled;
    screen.setState(obj);
  }

  public static setValueOf(
    screen: any,
    componentName: any,
    value: any,
    multipleValuePropName?: string | null,
    _pk?: any
  ) {
    if (!screen) return;
    // pagination case start
    if (
      screen[`${componentName}_kuikaPaginationRef`] != undefined ||
      screen[`${componentName}_kuikaPaginationRef`] != null
    ) {
      if (
        screen[`${componentName}_kuikaPaginationRef`].current != undefined &&
        screen[`${componentName}_kuikaPaginationRef`].current != null &&
        screen[`${componentName}_kuikaPaginationRef`].current.setPaginationData != undefined
      ) {
        if (
          multipleValuePropName === "pageSize" &&
          screen[`${componentName}_kuikaPaginationRef`].current.setPageSize != undefined
        ) {
          screen[`${componentName}_kuikaPaginationRef`].current.setPageSize(value);
        } else {
          screen[`${componentName}_kuikaPaginationRef`].current.setPaginationData(value);
        }
      }
    }
    // pagination case end
    // MicroApp
    else if (
      screen[`${componentName}_kuikaMicroAppRef`] != undefined ||
      screen[`${componentName}_kuikaMicroAppRef`] != null
    ) {
      if (screen[`${componentName}_kuikaMicroAppRef`].current) {
        if (multipleValuePropName === "workspace" && screen[`${componentName}_kuikaMicroAppRef`].current.setWorkspace) {
          screen[`${componentName}_kuikaMicroAppRef`].current.setWorkspace(value);
        } else if (
          multipleValuePropName === "appName" &&
          screen[`${componentName}_kuikaMicroAppRef`].current.setAppName
        ) {
          screen[`${componentName}_kuikaMicroAppRef`].current.setAppName(value);
        } else if (
          multipleValuePropName === "projectId" &&
          screen[`${componentName}_kuikaMicroAppRef`].current.setProjectId
        ) {
          screen[`${componentName}_kuikaMicroAppRef`].current.setProjectId(value);
        } else if (
          multipleValuePropName === "backEndUrl" &&
          screen[`${componentName}_kuikaMicroAppRef`].current.setBackEndUrl
        ) {
          screen[`${componentName}_kuikaMicroAppRef`].current.setBackEndUrl(value);
        }
      }
    } else if (
      screen[`${componentName}_kuikaChatRef`] != undefined &&
      screen[`${componentName}_kuikaChatRef`] != null &&
      screen[`${componentName}_kuikaChatRef`].current
    ) {
      if (multipleValuePropName === "message" && screen[`${componentName}_kuikaChatRef`].current.setMessage) {
        screen[`${componentName}_kuikaChatRef`].current.setMessage(value);
      }

      if (
        multipleValuePropName === "currentlyEditingMessageId" &&
        screen[`${componentName}_kuikaChatRef`].current.setCurrentlyEditingMessageId
      ) {
        screen[`${componentName}_kuikaChatRef`].current.setCurrentlyEditingMessageId(value);
      }
    } else if (
      screen[`${componentName}_kuikaWebViewRef`] != undefined ||
      screen[`${componentName}_kuikaWebViewRef`] != null
    ) {
      if (screen[`${componentName}_kuikaWebViewRef`].current) {
        if (screen[`${componentName}_kuikaWebViewRef`].current.setUrl) {
          screen[`${componentName}_kuikaWebViewRef`].current.setUrl(value);
        }
      }
    } else if (
      screen[`${componentName}_kuikaCarouselRef`] != undefined ||
      screen[`${componentName}_kuikaCarouselRef`] != null
    ) {
      if (screen[`${componentName}_kuikaCarouselRef`].current) {
        if (screen[`${componentName}_kuikaCarouselRef`].current.setStartingIndex) {
          screen[`${componentName}_kuikaCarouselRef`].current.setStartingIndex(value);
        }
      }
    }
    // LocationSearch CASE START
    else if (
      screen[`${componentName}_kuikaPlacesAutocompleteInputRef`] != undefined &&
      screen[`${componentName}_kuikaPlacesAutocompleteInputRef`] != null
    ) {
      if (screen[`${componentName}_kuikaPlacesAutocompleteInputRef`].current != null) {
        if (screen[`${componentName}_kuikaPlacesAutocompleteInputRef`].current.handleChange) {
          screen[`${componentName}_kuikaPlacesAutocompleteInputRef`].current.handleChange(value);
        }
      }
    }
    // LocationSearch CASE END
    else if (
      (screen[`${componentName}_kuikaDateRef`] != undefined ||
        screen[`${componentName}_kuikaDateRef`] != null ||
        screen[`${componentName}_kuikaDatePickerRef`] != undefined ||
        screen[`${componentName}_kuikaDatetimeRef`] != undefined) &&
      multipleValuePropName === "disablebeforedate"
    ) {
      let refText =
        screen[`${componentName}_kuikaDateRef`] != undefined
          ? "_kuikaDateRef"
          : screen[`${componentName}_kuikaDatePickerRef`] !== undefined
          ? "_kuikaDatePickerRef"
          : screen[`${componentName}_kuikaDatetimeRef`] !== undefined
          ? "_kuikaDatetimeRef"
          : "";
      if (screen[`${componentName}${refText}`].current) {
        if (screen[`${componentName}${refText}`].current.setDisableBeforeDate) {
          screen[`${componentName}${refText}`].current.setDisableBeforeDate(value);
        }
      }
    } else {
      const data = {};
      if (multipleValuePropName) {
        let obj = screen.props.form.getFieldValue(componentName);
        if (obj === undefined) {
          obj = {};
        }
        if (typeof obj !== "object" || obj === null) {
          obj = {};
        }
        obj[multipleValuePropName] = value;
        data[componentName] = obj;
      } else {
        data[componentName] = value;
      }

      screen.props.form.setFieldsValue(data);
      return Guid.create().toString();
    }
  }

  public static toggleLeftMenu(screen: any): string {
    screen.setState({ isLeftMenuVisible: !screen.state.isLeftMenuVisible });
    return "";
  }

  public static async changePassword(
    currentpassword: string,
    newpassword: string,
    dontSendEmail?: boolean,
    language?: string,
    callback?: () => {}
  ) {
    const credientials = {
      currentpassword,
      newpassword,
      newpasswordConfirm: newpassword,
      dontSendEmail: dontSendEmail || false,
      language: language || ""
    } as IChangePasswordDto;

    try {
      await UserService.ChangePassword(credientials);
    } catch (error) {
      KMainFunctions.exceptionHandler(error);
    } finally {
      if (callback) callback();
    }
  }

  public static readFromLocalStorage(key: string) {
    return window.localStorage.getItem(key);
  }

  public static writeToLocalStorage(key: string, value: string) {
    window.localStorage.setItem(key, value);
  }

  public static deleteFromLocalStorage(key: string) {
    window.localStorage.removeItem(key);
  }

  public static readFromSessionStorage(key: string) {
    return window.sessionStorage.getItem(key);
  }

  public static writeToSessionStorage(key: string, value: string) {
    window.sessionStorage.setItem(key, value);
  }

  public static deleteFromSessionStorage(key: string) {
    window.sessionStorage.removeItem(key);
  }

  public static isDate(value: any, _value2: any): string {
    let result = value instanceof Date || value instanceof moment;
    if (result === true) return "";
    result = isNaN(Date.parse(value)) === false || moment(value).isValid();
    return result === true ? "" : "Invalid Date";
  }

  public static isEmpty(val: any, _val2?: any): boolean {
    if (typeof val === "number") {
      return false;
    }
    return _.isEmpty(val);
  }

  // setValueOfDI: _setValueOfDI,

  // Comparison
  public static isGreaterThan(val1: string | any[], val2: string | any[]): boolean {
    if (val1 === undefined || val2 === undefined || val1 === null || val2 === null) return false;
    if (val1.length === 0 || val2.length === 0) return false;
    return Number(val1) > Number(val2);
  }

  public static isGreaterOrEqual(val1: string | any[], val2: string | any[]): boolean {
    if (val1 === undefined || val2 === undefined || val1 === null || val2 === null) return false;
    if (val1.length === 0 || val2.length === 0) return false;
    return Number(val1) >= Number(val2);
  }

  public static isLessThan(val1: string | any[], val2: string | any[]): boolean {
    if (val1 === undefined || val2 === undefined || val1 === null || val2 === null) return false;
    if (val1.length === 0 || val2.length === 0) return false;
    return Number(val1) < Number(val2);
  }

  public static isLessOrEqual(val1: string | any[], val2: string | any[]): boolean {
    if (val1 === undefined || val2 === undefined || val1 === null || val2 === null) return false;
    if (val1.length === 0 || val2.length === 0) return false;
    return Number(val1) <= Number(val2);
  }

  public static isEqualTo(val1: string | boolean, val2: string | boolean): boolean {
    if (val1 === undefined || val2 === undefined || val1 === null || val2 === null) return false;
    if (
      (val1 === "true" && val2 === true) ||
      (val1 === "false" && val2 === false) ||
      (val1 === true && val2 === "true") ||
      (val1 === false && val2 === "false")
    ) {
      return true;
    }
    return val1 == val2;
  }

  public static isShorterOrEqualLength(val1: string, val2: null): boolean {
    if (val1 === undefined || val2 === undefined || val1 === null || val2 === null) return false;
    return val1.length <= Number(val2);
  }

  public static isShorterLength(val1: string, val2: null): boolean {
    if (val1 === undefined || val2 === undefined || val1 === null || val2 === null) return false;
    return val1.length < Number(val2);
  }

  public static isLongerLength(val1: string, val2: null): boolean {
    if (val1 === undefined || val2 === undefined || val1 === null || val2 === null) return false;
    return val1.length > Number(val2);
  }

  public static isLongerOrEqualLength(val1: string, val2: null): boolean {
    if (val1 === undefined || val2 === undefined || val1 === null || val2 === null) return false;
    return val1.length >= Number(val2);
  }

  public static isEqualLength(val1: string, val2: null): boolean {
    if (val1 === undefined || val2 === undefined || val1 === null || val2 === null) return false;
    return val1.length === Number(val2);
  }

  public static isNotEqualTo(val1: any, val2: any): boolean {
    if (
      (val1 === "true" && val2 === true) ||
      (val1 === "false" && val2 === false) ||
      (val1 === true && val2 === "true") ||
      (val1 === false && val2 === "false")
    ) {
      return false;
    }

    return val1 != val2;
  }

  public static startsWith(val1: string, val2: string | any): boolean {
    if (val1 === undefined || val2 === undefined) return false;
    if (val1 === null || val2 === null) return false;
    if (val1.length === 0 || val2.length === 0) return false;
    if (val1.startsWith && val1.startsWith(val2)) return true;
    return false;
  }

  public static doesNotStartWith(val1: string, val2: string | any): boolean {
    if (val1 === undefined || val2 === undefined) return false;
    if (val1 === null || val2 === null) return false;
    if (val1.length === 0 || val2.length === 0) return false;
    if (!val1.startsWith(val2)) return true;
    return false;
  }

  public static endsWith(val1: string, val2: string | any): boolean {
    if (val1 === undefined || val2 === undefined) return false;
    if (val1 === null || val2 === null) return false;
    if (val1.length === 0 || val2.length === 0) return false;
    if (val1.endsWith && val1.endsWith(val2)) return true;
    return false;
  }

  public static contains(val1: string | any[], val2: string | any): boolean {
    if (val1 === undefined || val2 === undefined || val1 === null || val2 === null) return false;
    if (val1.length === 0 || val2.length === 0) return false;
    if (val1.indexOf && val1.indexOf(val2) > -1) return true;
    return false;
  }

  public static isEarlierThan(val1: moment.MomentInput, val2: moment.MomentInput): boolean {
    if (val1 === null || val2 === null) return false;
    if (val1 === undefined || val2 === undefined) return false;
    return moment(val1).isBefore(moment(val2));
  }

  public static isEarlierOrEqual(val1: moment.MomentInput, val2: moment.MomentInput): boolean {
    if (val1 === null || val2 === null) return false;
    if (val1 === undefined || val2 === undefined) return false;
    return moment(val1).isSameOrBefore(moment(val2), "day");
  }

  public static isLaterThan(val1: moment.MomentInput, val2: moment.MomentInput): boolean {
    if (val1 === undefined || val2 === undefined) return false;
    return moment(val1).isAfter(moment(val2));
  }

  public static isLaterOrEqual(val1: moment.MomentInput, val2: moment.MomentInput): boolean {
    if (val1 === null || val2 === null) return false;
    if (val1 === undefined || val2 === undefined) return false;
    return moment(val1).isSameOrAfter(moment(val2), "day");
  }

  /* public static isSameDay(val1, val2): boolean {
    if (val1 === null || val2 === null) return false;
    if (val1 === undefined || val2 === undefined) return false;
    alert("TODO: SYSTEM FUNCTION NOT IMPLEMENTED YET: isSameDay");
    return false;
  }

  public static isSameMonth(val1, val2): boolean {
    if (val1 === null || val2 === null) return false;
    if (val1 === undefined || val2 === undefined) return false;
    alert("TODO: SYSTEM FUNCTION NOT IMPLEMENTED YET: isSameMonth");
    return false;
  }

  public static isSameYear(val1, val2): boolean {
    if (val1 === null || val2 === null) return false;
    if (val1 === undefined || val2 === undefined) return false;
    alert("TODO: SYSTEM FUNCTION NOT IMPLEMENTED YET: isSameYear");
    return false;
  }
  */

  public static isNotEmpty(val: any, _x?: any): boolean {
    if (val === 0) {
      return true;
    }
    if (!val || val == "") {
      return false;
    }
    return true;
  }

  public static isGuid(val): boolean {
    return Guid.isGuid(val);
  }

  public static scanBarcode() {}

  // Remaining
  // isValid: _isValid,
  public static isValid(val1: any): boolean {
    if (typeof val1 === "string") return val1.length === 0;
    if (val1 === false) return false;
    if (val1 === true) return true;
    return true;
  }

  public static validateToken() {}

  public static async downloadFile(data: string | IRestResult, filename?: string): Promise<void> {
    if (!data || data === "") {
      this.notify(undefined, "Invalid file data.", "warning", "bottom-right", 3, null, null, null);
      return;
    }
    if (typeof data === "string" && KMainFunctions.isValidUrl(data)) {
      const blob: Blob = await KMainFunctions.toBlob(data);
      KMainFunctions.downloadFile(
        blob,
        KMainFunctions.generateFilenameForDownloadFile(filename, KMainFunctions.getExtensionByMimeType(blob.type))
      );
      return;
    }

    if (typeof data === "string" && Guid.parse(data).toString() !== Guid.EMPTY) {
      const url = KMainFunctions.getUrlByWithResourceId(data);
      const blob: Blob = await KMainFunctions.toBlob(url);
      KMainFunctions.downloadFile(
        blob,
        KMainFunctions.generateFilenameForDownloadFile(filename, KMainFunctions.getExtensionByMimeType(blob.type))
      );
      return;
    }

    if (typeof data === "string" && KMainFunctions.isRelativeUrl(data)) {
      const backendUrl: string = KuikaAppManager.GetBackendUrl();
      const absoluteUrl: string = data.startsWith("/") ? `${backendUrl}${data}` : `${backendUrl}/${data}`;
      const blob: Blob = await KMainFunctions.toBlob(absoluteUrl);
      KMainFunctions.downloadFile(
        blob,
        KMainFunctions.generateFilenameForDownloadFile(filename, KMainFunctions.getExtensionByMimeType(blob.type))
      );
      return;
    }

    if (typeof data === "string" && KMainFunctions.isBase64(data)) {
      const blob: Blob = await KMainFunctions.toBlob(data);
      KMainFunctions.downloadFile(
        blob,
        KMainFunctions.generateFilenameForDownloadFile(filename, KMainFunctions.getExtensionByMimeType(blob.type))
      );
      return;
    }

    if (typeof data === "object") {
      const blob: Blob = await KMainFunctions.toBlob(
        `${KMainFunctions.getBase64UrlPrefix(data.mimeType)}${data.content}`
      );
      KMainFunctions.downloadFile(
        blob,
        KMainFunctions.generateFilenameForDownloadFile(
          filename ?? data.fileName,
          KMainFunctions.getExtensionByMimeType(blob.type)
        )
      );
      return;
    }

    this.notify(undefined, "Invalid file data.", "warning", "bottom-right", 3, null, null, null);
    return;
  }

  public static getLanguage(_languages: any, defaultLanguage: string): string {
    let retVal = "";
    const mlFromLocalStorage = localStorage.getItem("ml");
    if (!mlFromLocalStorage) {
      if (defaultLanguage) {
        localStorage.setItem("ml", defaultLanguage);
        retVal = defaultLanguage;
      }
    } else {
      retVal = mlFromLocalStorage;
    }
    return retVal;
  }

  public static toggleMenuCollapse(screen: any, stateName: string) {
    const obj = {} as any;
    var tmp = Object.keys(screen.props.form.getFieldsValue()).find((v) => v.includes(stateName));

    var value = screen.props.form.getFieldValue(tmp);
    obj.tmp = value;
    obj.tmp.collapsed = !obj.tmp.collapsed;
    screen.props.form.setFieldsValue(!value);
  }

  public static setLanguage(_screen: any, culture: string) {
    const currentML = localStorage.getItem("ml");
    if (currentML && currentML != culture) {
      localStorage.setItem("ml", culture);
      if (localStorage.getItem("refreshToken") && localStorage.getItem("refreshToken").length) {
        LanguageService.SaveLanguage(culture);
      }
      window.location.reload();
    } else {
      localStorage.setItem("ml", culture);
      if (localStorage.getItem("refreshToken") && localStorage.getItem("refreshToken").length) {
        LanguageService.SaveLanguage(culture);
      }
    }
  }

  public static getAvailableLanguages(languages: any[]) {
    const languageList: any = [];
    languages?.map((x: { ShortName: string; IsDefault: any; Name: any }) => {
      if (x.ShortName !== "FixedValues") {
        languageList.push({
          shortName: x.ShortName,
          isDefault: x.IsDefault,
          name: x.Name
        });
      }
    });
    return languageList;
  }

  public static translateCustomActions(languages: any, actionId: any, defaultLanguage: any, defaultValue: string) {
    let retVal = "";
    const lastUnderscoreIndex = actionId.lastIndexOf("_");
    const stringWithoutLastUnderscore = actionId.substring(0, lastUnderscoreIndex).replace(/_/g, "-");
    actionId = stringWithoutLastUnderscore + actionId.substring(lastUnderscoreIndex);
    let selectedLanguage = languages?.find(
      (x: { Name: string }) => x.Name === this.getLanguage(languages, defaultLanguage)
    );
    if (!selectedLanguage)
      selectedLanguage = languages.find((x: { ShortName: string }) => x.ShortName == "FixedValues");
    if (selectedLanguage !== undefined) {
      const item = selectedLanguage?.LanguagePhrases?.find(
        (x: { Id: any; PropertyName: any }) => x.PropertyName === actionId
      );
      retVal = item?.Value ? item.Value : "";

      if (retVal === "") {
        const fixedValues = languages.find((x: { ShortName: string }) => x.ShortName == "FixedValues");
        if (fixedValues) {
          const phrase = fixedValues.LanguagePhrases.find(
            (x: { Id: any; PropertyName: any }) => x.PropertyName === actionId
          );
          if (phrase) retVal = phrase.Value;
        }
      }
    }
    return retVal.length ? retVal : defaultValue;
  }

  public static translate(languages: any, diId: any, propertyName: any, defaultLanguage: any) {
    let retVal = "";
    let selectedLanguage = languages?.find(
      (x: { Name: string }) => x.Name === this.getLanguage(languages, defaultLanguage)
    );
    if (!selectedLanguage)
      selectedLanguage = languages.find((x: { ShortName: string }) => x.ShortName == "FixedValues");
    if (selectedLanguage !== undefined) {
      const item = selectedLanguage?.LanguagePhrases?.find(
        (x: { Id: any; PropertyName: any }) => x.Id === diId && x.PropertyName === propertyName
      );
      retVal = item?.Value ? item.Value : "";

      if (retVal === "") {
        const fixedValues = languages.find((x: { ShortName: string }) => x.ShortName == "FixedValues");
        if (fixedValues) {
          const phrase = fixedValues.LanguagePhrases.find(
            (x: { Id: any; PropertyName: any }) => x.Id === diId && x.PropertyName === propertyName
          );
          if (phrase) retVal = phrase.Value;
        }
      }
    }
    return retVal;
  }

  public static getEmailValidationMessage() {
    const language = localStorage.getItem("ml");
    let retValue = "";
    switch (language) {
      case "en_US":
        retValue = "Please Enter a Valid Email.";
        break;

      case "fr_FR":
        retValue = "Veuillez saisir un e-mail valide.";
        break;

      case "tr_TR":
        retValue = "Geçerli bir mail adresi giriniz.";
        break;
      default:
        retValue = "Please Enter a Valid Email.";
        break;
    }
    return retValue;
  }

  public static openInMap(p1: any, p2: any, _p3: any, _p4: any) {
    window.open(`https://www.google.com/maps/@${p1},${p2},12.00z`);
    return;
  }

  public static phoneNumberCall(number: any) {
    window.open(`tel:${number}`);
  }
  // validatePhoneNumber: _validatePhoneNumber,
  // validateEmail: _validateEmail,

  // toLocaleString: _toLocaleString,
  // trimRight: _trimRight,
  // toTitleCase: _toTitleCase,
  // substring: _substring,
  // concatenate: _concatenate,

  // registerPopup: _registerPopup,
  // deregisterPopup: _deregisterPopup,

  // attachFile: _attachFile,
  // attachFileUrl: _attachFileUrl,
  // downloadFile: _downloadFile,
  // downloadFileUrl: _downloadFileUrl,

  // scanBarcodeKuikaFunction: _scanBarcodeKuikaFunction,

  // searchOnClientSide: _searchOnClientSide,
  // kuikaSearchOnClientSide: _kuikaSearchOnClientSide,

  // getFromLangPack: _getFromLangPack,
  // setLanguage: _setLanguage,
  // getLanguage: _getLanguage,
  // getAvailableLanguages: _getAvailableLanguages,
  // translate: _translate,
  // translateToStringArray: _translateToStringArray,

  // isNotEmpty: _isNotEmpty,
  // getColorForTableRow: _getColorForTableRow,
  // increment: _increment,
  // and: _and,
  // or: _or,
  // not: _not,

  // base64ToBlob: _base64ToBlob,
  // downloadFile: _downloadFile,
  // authGoogleExternalLogin: _authGoogleExternalLogin,
  public static goToFullScreen(): void {
    const elem: any = document.getElementsByTagName("html")[0];

    const urlParams = new URLSearchParams(window.location.search);
    const redirectOnMobileValue = urlParams.get("redirectOnMobile");

    if (redirectOnMobileValue === "true") {
      this.postWebKitMessage(null, {
        actionType: "GO_TO_FULL_SCREEN"
      });
    } else if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  }

  public static getLocale(): any {
    let mlKey = localStorage.getItem("ml");
    if (!mlKey) {
      mlKey = "en_US";
    }
    switch (mlKey) {
      case "ar_EG":
        return ar_EG;

      case "cs_CZ":
        return cs_CZ;

      case "da_DK":
        return da_DK;

      case "de_DE":
        return de_DE;

      case "en_US":
        return en_US;

      case "en_GB":
        return en_GB;

      case "fr_FR":
        return fr_FR;

      case "it_IT":
        return it_IT;

      case "nl_NL":
        return nl_NL;

      case "tr_TR":
        return tr_TR;

      case "es_ES":
        return es_ES;

      default:
        return en_US;
    }
  }

  public static updateUserName(
    currentUserName: string,
    newUserName: string,
    dontSendEmail?: boolean,
    language?: string
  ): void {
    const obj = {
      userName: currentUserName,
      newUserName,
      dontSendEmail: dontSendEmail || false,
      language: language || ""
    } as IUpdateUsernameDto;
    UserService.UpdateUserName(obj);
  }

  public static updateUserStartingScreen(currentUserName: string, startingScreenId: string): void {
    const obj: IUpdateUserStartingScreen = {
      userName: currentUserName,
      startingScreenId
    };
    UserService.UpdateUserStartingScreen(obj);
  }

  public static async componentToPDF(targetComponent: string, quality: string, orientation: string) {
    const options: any = {
      method: "build",
      resolution: Resolution[quality],
      overrides: {
        pdf: {
          compress: true
        },
        canvas: {
          useCORS: true
        }
      },
      page: {
        orientation: orientation ?? "portrait"
      }
    };
    const getTargetElement = () => document.getElementById(targetComponent);
    var a = await generatePDF(getTargetElement, options);
    var blobValue = a.output("blob");
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blobValue);
    });
  }

  public static async componentToPDFSave(
    targetComponent: string,
    filename: string,
    quality: string,
    orientation: string
  ) {
    let file = filename;
    if (!file.endsWith(".pdf")) {
      file = `${file}.pdf`;
    }

    const options: any = {
      filename: file,
      method: "save",
      resolution: Resolution[quality],
      overrides: {
        pdf: {
          compress: true
        },
        canvas: {
          useCORS: true
        }
      },
      page: {
        orientation: orientation ?? "portrait"
      }
    };
    const getTargetElement = () => document.getElementById(targetComponent);
    await generatePDF(getTargetElement, options);
  }

  public static scrollTo(elemenetId: string) {
    if (!elemenetId) return;
    const element = window.document.getElementById(elemenetId);
    if (!element) return;
    element.scrollIntoView({ behavior: "smooth" });
  }

  public static setFocusTo(elementId: string) {
    if (!elementId) return;
    const element = window.document.getElementById(elementId);
    const element2: HTMLElement = window.document.querySelector(`[focusId="${elementId}"]`);
    if (!element && !element2) return;
    setTimeout(() => {
      element?.focus();
      element2?.focus();
    }, 0);
  }

  public static openPdf(data: string, warnLandscape: boolean, openAs: "newTab" | "currentPage"): void {
    if (KMainFunctions.isBase64(data) || data.startsWith("data:application/pdf;base64")) {
      KMainFunctions.openBase64Pdf(data, warnLandscape, openAs);
      return;
    }

    if (KMainFunctions.isValidUrl(data)) {
      KMainFunctions.openUrlPdf(data, openAs);
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const redirectOnMobileValue = urlParams.get("redirectOnMobile");

    if (redirectOnMobileValue === "true") {
      this.postWebKitMessage(null, {
        actionType: "GET_REPORT",
        link: `${data}`,
        warnLandscape: `${warnLandscape}`,
        openAs: `${openAs}`
      });
      return;
    }

    this.notify(undefined, "Invalid pdf data.", "warning", "bottom-right", 3, null, null, null);
    return;
  }

  public static async share(data: string): Promise<void> {
    try {
      if (!navigator.share || !data) return;

      let text: string | undefined;
      let files: File[];

      if (data.startsWith("VCARD")) {
        const blob: Blob = await KMainFunctions.toBlob(data);
        files = [new File([blob], "Share.vcf", { type: "text/vcard" })];
      } else if (
        (data.toLowerCase().startsWith("http://") || data.toLowerCase().startsWith("https://")) &&
        data.toLowerCase().endsWith(".pdf") &&
        data.indexOf(" ") < 0
      ) {
        const blob: Blob = await KMainFunctions.toBlob(data);
        files = [new File([blob], `${uuid}.${blob.type.split("/")[1]}`, { type: blob.type })];
      } else {
        text = data;
      }
      await navigator.share({ text, files });
    } catch (e) {
      KMainFunctions.exceptionHandler(e);
    }
  }

  public static selectLanguage(): string {
    return "";
  }

  public static getVersionInfo(): string {
    return "1.0.0";
  }

  public static getMyNotifications(_username: any, _no: any, _no2: any, _no3: any) {
    console.log("TODO: SYSTEM FUNCTION NOT IMPLEMENTED YET: getMyNotifications");
  }

  public static markAsReadNotification(_username: any, _notificationId: any) {
    console.log("TODO: SYSTEM FUNCTION NOT IMPLEMENTED YET: markAsReadNotification");
  }

  public static async sendPushNotificationToUsername(
    username: string,
    title: string,
    message: string,
    extraData: any
  ): Promise<void> {
    NotificationService.SendPushNotificationToUsername({
      username,
      title,
      message,
      extraData
    });
  }

  public static isValidEmail(val1: any, _val2: any): any {
    if (!val1) return false;
    return /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/.test(
      val1?.toString()
    );
    // return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(val1?.toString());
  }

  public static SendPushNotification(p1, p2, p3) {}

  public static async isLoggedIn() {
    const token = localStorage.getItem("tokenString");
    if (token && token.length > 0) {
      const result = await UserService.ValidateToken();
      return KMainFunctions.IsResStatus2XX(result);
    }
    return false;
  }

  // STRIPE ACTIONS START
  public static async stripePayment(amount: string, currency: string, description: string): Promise<IStripePayment> {
    const stripePaymentDto = {
      amount,
      currency,
      description
    } as IStripePaymentDto;
    const res = await StripeService.OneTimePayment(stripePaymentDto);
    if (res.status === 200) {
      return res.data;
    }
    return {} as IStripePayment;
  }

  public static async getPaymentReceiptLink(paymentIntent: string): Promise<string> {
    const dto = {
      paymentIntent
    } as IStripeCaptureDto;
    const res = await StripeService.GetPaymentReceiptLink(dto);
    if (res.status === 200) {
      return res.data;
    }
    return {} as any;
  }

  public static async getPaymentReceiptBase64(paymentIntent: string): Promise<string> {
    const dto = {
      paymentIntent
    } as IStripeCaptureDto;
    const res = await StripeService.GetPaymentReceiptBase64(dto);
    if (res.status === 200) {
      return res.data;
    }
    return {} as any;
  }

  public static async checkPaymentIntentStatus(paymentIntent: string): Promise<any> {
    const dto = {
      paymentIntent
    } as IStripeCaptureDto;
    const res = await StripeService.CheckPaymentIntentStatus(dto);
    if (res.status === 200) {
      return res;
    }
    return {} as any;
  }

  public static async openStripePaymentDrawer(
    screen: any,
    cllbck: any,
    amount: string,
    currency: string,
    description: string,
    applicationFee: string,
    destination: string,
    captureMethod: string,
    paymentType: string,
    supportedPaymentTypes?: string
  ): Promise<any> {
    const stripePaymentDto = {
      amount,
      currency,
      description,
      applicationFeeAmount: applicationFee,
      ConnectedAccount: destination,
      captureMethod,
      supportedPaymentTypes
    } as IStripePaymentDto;
    const endPoint =
      paymentType === "single" ? StripeService.OneTimePayment : StripeService.OneTimePaymentWithDestination;
    const res = await endPoint(stripePaymentDto);
    if (res.status === 200) {
      const response = {};
      screen?.props.showStripeDrawer(res.data, cllbck, screen, false);
      return res.data;
    }
    return "";
  }

  public static async setupIntent(screen: any, cllbck: any, supportedPaymentTypes: string): Promise<any> {
    const stripePaymentDto = {
      supportedPaymentTypes
    } as any;
    const res = await StripeService.SetupIntent(stripePaymentDto);
    if (res.status === 200) {
      const response = {};
      screen?.props.showStripeDrawer(res.data, cllbck, screen, true);
      return res.data;
    }
    return "";
  }

  public static async refundStripePayment(paymentIntent: string, reason: string, amount: string): Promise<boolean> {
    const stripePaymentDto = {
      amount,
      reason,
      paymentIntent
    } as IStripeRefundDto;
    const res = await StripeService.RefundPayment(stripePaymentDto);
    if (res.status === 200) {
      return res.data;
    }
    return false;
  }

  public static async captureReservedFunds(paymentIntent: string, amount: string): Promise<boolean> {
    const stripePaymentDto = {
      amount,
      paymentIntent
    } as IStripeCaptureDto;
    const res = await StripeService.CapturePayment(stripePaymentDto);
    if (res.status === 200) {
      return res.data;
    }
    return false;
  }

  public static async chargePaymentMethod(
    paymentmethod: string,
    currency: string,
    amount: string,
    stripeCustomerId: string,
    description: string,
    paymenttype: string,
    feeamount: string,
    destination: string
  ): Promise<any> {
    const stripePaymentDto = {
      paymentmethod,
      amount,
      currency,
      stripeCustomerId,
      description,
      paymenttype,
      feeamount,
      destination
    } as IStripeChargePaymentMethodDto;
    const res = await StripeService.ChargePaymentMethod(stripePaymentDto);
    if (res.status === 200) {
      return res.data;
    }
    return false;
  }

  public static async getStripeMasterAccountBalance(secretKey: string): Promise<any> {
    const stripePaymentDto = {
      secretKey
    } as IStripeMasterAccountDto;
    const res = await StripeService.GetMasterAccountBalance(stripePaymentDto);
    if (res.status === 200) {
      return res.data;
    }
    return undefined;
  }

  public static async getStripeMasterAccountBalanceHistory(secretKey: string, count: number): Promise<any> {
    const stripePaymentDto = {
      secretKey,
      count
    } as IStripeMasterAccountDetailsDto;
    const res = await StripeService.GetMasterAccountBalanceHistory(stripePaymentDto);
    if (res.status === 200) {
      return res.data;
    }
    return undefined;
  }

  public static async getConnectedAccountBalance(connectedAccount: string): Promise<any> {
    const stripePaymentDto = {
      connectedAccount
    } as IStripeConnectedAccountDto;
    const res = await StripeService.GetConnectedAccountBalance(stripePaymentDto);
    if (res.status === 200) {
      return res.data;
    }
    return undefined;
  }

  public static async getConnectedAccountStatus(connectedAccount: string): Promise<any> {
    const stripePaymentDto = {
      connectedAccount
    } as IStripeConnectedAccountDto;
    const res = await StripeService.GetConnectedAccountStatus(stripePaymentDto);
    if (res.status === 200) {
      return res.data;
    }
    return false;
  }

  public static async getConnectedAccountOnboardingLink(connectedAccount: string): Promise<any> {
    const stripePaymentDto = {
      connectedAccount,
      returnLocation: KMainFunctions.windowLocationOrigin
    } as IStripeConnectedAccountOnboardingDto;
    const res = await StripeService.GetConnectedAccountOnboardingLink(stripePaymentDto);
    if (res.status === 200) {
      return res.data;
    }
    return KMainFunctions.windowLocationOrigin;
  }

  public static async createConnectedAccount(type: string, country: string, email: string): Promise<any> {
    const stripePaymentDto = {
      type,
      country,
      email
    } as IStripeCreateConnectedAccountDto;
    const res = await StripeService.CreateConnectedAccount(stripePaymentDto);
    if (res.status === 200) {
      return res.data;
    }
    return "";
  }

  public static async connectedAccountWithdraw(
    amount: string,
    connectedAccount: string,
    currency: string
  ): Promise<any> {
    const stripePaymentDto = {
      amount,
      connectedAccount,
      currency
    } as IStripeConnectedAccountWithdraw;
    const res = await StripeService.ConnectedAccountWithdraw(stripePaymentDto);
    if (res.status === 200) {
      return res.data;
    }
    return "";
  }

  public static async connectedAccountPayout(amount: string, connectedAccount: string, currency: string): Promise<any> {
    const stripePaymentDto = {
      amount,
      connectedAccount,
      currency
    } as IStripeConnectedAccountPayout;
    const res = await StripeService.ConnectedAccountPayout(stripePaymentDto);
    if (res.status === 200) {
      return res.data;
    }
    return "";
  }

  public static async cancelStripePayment(paymentIntent: string): Promise<boolean> {
    const dto = {
      paymentIntent
    } as IStripeCaptureDto;
    const res = await StripeService.CancelPaymentIntent(dto);
    if (res.status === 200) {
      return res.data;
    }
    return false;
  }

  public static async updateStripeUserInfo(
    email: string,
    name: string,
    phone: string,
    description: string
  ): Promise<boolean> {
    const dto = {
      email,
      name,
      phone,
      description
    } as IStripeUpdateCustomerDto;
    const res = await StripeService.UpdateStripeUserInfo(dto);
    if (res.status === 200) {
      return res.data;
    }
    return false;
  }

  public static async removeStripePaymentMethod(paymentmethod: string): Promise<any> {
    const stripePaymentDto = {
      paymentmethod
    } as IStripeDeletePaymentMethodDto;
    const res = await StripeService.DeletePaymentMethod(stripePaymentDto);
    if (res.status === 200) {
      return res.data;
    }
    return undefined;
  }

  public static async getCustomerPaymentMethods(): Promise<any> {
    const res = await StripeService.GetCustomerPaymentMethods();
    if (res.status === 200) {
      return res;
    }
    return undefined;
  }

  public static async getCustomerPaymentMethodsbyId(stripeCustomerId: string): Promise<any> {
    const res = await StripeService.GetCustomerPaymentMethodsbyId({ stripeCustomerId });
    if (res.status === 200) {
      return res;
    }
    return undefined;
  }

  // STRIPE ACTIONS END
  public static printPDFQZ(
    printerName: string,
    pdfBase64: string,
    pageW: number,
    pageH: number,
    totalHeight: number,
    stickyPaper: boolean,
    onePage: boolean,
    pdfList: any
  ): boolean {
    const privateKey = `-----BEGIN PRIVATE KEY-----
    MIIEvwIBADANBgkqhkiG9w0BAQEFAASCBKkwggSlAgEAAoIBAQDuSx1EduN/GsG1
    VqyRoNHlnHckbGyYTlyltzBFnSzwfdWTLEqV9IRH20wmz8IaVO06yYI+BVbm5b2q
    Rc4YHQswUKQzap9bbaGrl4sW6dYOJwCCRziu8iBMdu0xH5oziHFCHgXTg5o3mDLE
    XAMDCkoMnqnjepf9MvM9oztNQRftKNh0DfygqMrdIUfNQOu8gqSJ7JKYXXaXIYlQ
    yud032xyAfUOkPq4EcerBSLttQxHDa9oUYBeuRoHK18iUH+vOCw8gDQivRJ9w42E
    reg5cl7rykhjTQAmRubOntKCPEZT+WlWEkj/AxGoeNcnvXTpq9fmH8ioOR8S2MZA
    jTThSquLAgMBAAECggEAUrrvWVKxXvKKKAwEc7bSyJXO421lMmeaUMpNzw1nicMW
    QDdTWcZ/mnbvdFYS/JNZnVxShHfUGOvQMmpzx/llZgvJRjSRSOvCAlHlrznV4Cn4
    SkKx0kMrpYBwjup63cNUFvFXcnyxMPX9QiDzxr1uwfzf3KM2W2ix/EdMkmC0DtjA
    86/tqIXSksjGYpSJ9r9CcBRrYC5rd0QhMPav+sk5W9NF5OKjVP5LhBCwdc2kDywU
    zi0+2I7T1C4zSUHLpIKE0FPioQO5XlZ0Q4dDh3b4tlOAd2R9+YC9aAOGKmIZ/BhF
    N673ymjEVnw/LwiAEnLV3z+cClhvuIa4uj4yCssBiQKBgQD5ILI9aQr3iBMLv4f/
    czGgz/CW6k9bjebSOZf9SzpasTgzYMl/34jswO8UXwkupGsqksMKLMVwEuVoH3hp
    JpCIV9W/0/fA75eHxdbbFsiCyrbD0nnOYyM/MICzlYr6fokqR0cWoAms436/27w2
    j3x2Zw4muCy32OMSRef7X73xjQKBgQD03ehlJVPqNeK8PDmE0Qm2lYJitlO7fm1U
    WTYfN+L44EAzpv/+FbB808iqLeMIQdaw9XEm4x4tlSegUKJozLNfI3OGsYIFPMKD
    OEH7kGFVDe94m1hqnW2iAYu9ZlDS4WVLmnzCRTmbgQYiMjtVKL+BhDpQWvPdDNCS
    LkF9f2CvdwKBgQCvwSRd9KxbAzHEmQP98Kuzct+d6IHB6nNMHnueo6LwdpRaks61
    GuODe31vlrhoonBEYbOdiwZjUcFsWr9dF2rh9W2D3qur/pudmrJAcGtW8mJL9kIe
    m4wzOSmjCD47CGgGXrDFQnRw9zHlcsTujP3Wnk+6ezZ5IVbY/l5x1hKvxQKBgQCo
    doMME0JIQApdtpFb7vTfyLyLbSTuOWy+OyIhGDHvR1jkMGCuTf2943aKnkczxxgN
    Zs8SEe8RLSy1/sO9lhdRvPIeGMF7GPI5lNrQV+Sev5ehQj7yGpzyeF3LUDkK3Eb0
    XLS4cyfO9FVj8Wsgi4g1cuMrpWdhDQ/xgiFMVfYOwQKBgQDfMQtOzuZV99IT06Qu
    erlLLg3pkC5RE5b2lsHmXOS0J0K7Wd8QjvmCPxdrympyQmAFHz+4+0Xw5WOaDD5e
    s5GI6sJs+6/lMiAJV3UQhp0FpU5OhhLOz/VpaCTTcYP/OoS5ajKsS8rnCt3T2YAF
    CcbqViPtkJAPIj35xNBGC4K3gw==
    -----END PRIVATE KEY-----`;

    qz.security.setSignatureAlgorithm("SHA512"); // Since 2.1
    qz.security.setSignaturePromise((toSign: any) => {
      return function (resolve: (arg0: any) => void, reject: (arg0: any) => void) {
        try {
          const pk = KEYUTIL.getKey(privateKey);
          const sig = new KJUR.crypto.Signature({ alg: "SHA512withRSA" }); // Use "SHA1withRSA" for QZ Tray 2.0 and older
          sig.init(pk);
          sig.updateString(toSign);
          const hex = sig.sign();
          resolve(stob64(hextorstr(hex)));
        } catch (err) {
          console.error(err);
          reject(err);
        }
      };
    });

    try {
      const config = qz.configs.create(printerName);
      config.config = {
        orientation: "portrait",
        size: {
          width: pageW,
          height: pageH
        },
        units: "in"
      };

      if (totalHeight == 0) totalHeight = pageH;

      qz.security.setCertificatePromise((resolve: (arg0: string) => void, _reject: any) => {
        resolve(`-----BEGIN CERTIFICATE-----
          MIID4TCCAsmgAwIBAgIUTix9J/qAFmdZj1DlZ32r5UCBQzgwDQYJKoZIhvcNAQEL
          BQAwfzELMAkGA1UEBhMCVFIxCzAJBgNVBAgMAlRSMQswCQYDVQQHDAJUUjEOMAwG
          A1UECgwFSURBVEExDjAMBgNVBAsMBUlEQVRBMRcwFQYDVQQDDA4qLmlkYXRhLmNv
          bS50cjEdMBsGCSqGSIb3DQEJARYOKi5pZGF0YS5jb20udHIwIBcNMjIwNDIwMTQ0
          MDQwWhgPMjA1MzEwMTMxNDQwNDBaMH8xCzAJBgNVBAYTAlRSMQswCQYDVQQIDAJU
          UjELMAkGA1UEBwwCVFIxDjAMBgNVBAoMBUlEQVRBMQ4wDAYDVQQLDAVJREFUQTEX
          MBUGA1UEAwwOKi5pZGF0YS5jb20udHIxHTAbBgkqhkiG9w0BCQEWDiouaWRhdGEu
          Y29tLnRyMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA7ksdRHbjfxrB
          tVaskaDR5Zx3JGxsmE5cpbcwRZ0s8H3VkyxKlfSER9tMJs/CGlTtOsmCPgVW5uW9
          qkXOGB0LMFCkM2qfW22hq5eLFunWDicAgkc4rvIgTHbtMR+aM4hxQh4F04OaN5gy
          xFwDAwpKDJ6p43qX/TLzPaM7TUEX7SjYdA38oKjK3SFHzUDrvIKkieySmF12lyGJ
          UMrndN9scgH1DpD6uBHHqwUi7bUMRw2vaFGAXrkaBytfIlB/rzgsPIA0Ir0SfcON
          hK3oOXJe68pIY00AJkbmzp7SgjxGU/lpVhJI/wMRqHjXJ7106avX5h/IqDkfEtjG
          QI004UqriwIDAQABo1MwUTAdBgNVHQ4EFgQU9+kYlFmzC7HX7RGKDZ3KJJj4l8Yw
          HwYDVR0jBBgwFoAU9+kYlFmzC7HX7RGKDZ3KJJj4l8YwDwYDVR0TAQH/BAUwAwEB
          /zANBgkqhkiG9w0BAQsFAAOCAQEAQbRjJC6k5ZFYOgHTAhBsrwsvZ6ukbzEHbBhC
          rbE7NzTYS2QhYMbYM6O0e0jJ57dnEk03V4fXuaKLRVfKAqwFNEZgydwOZ7rZXi6O
          6Pi2IIxN7LHYtYrZwnANaWfUvwbULa439Fh1L5mVbKNpI0qLoC8JSO1gn+7BFv0v
          4AkgeFoEXa2IqlpupGIHFKPNA3M3RGNNFrysDDDKwV+cc2oAmXnxsEPI59sF4D1C
          l71cSa0tP2ZrHiv6PqgYQRSp4Tx2NlohkAmR/cZYmHjWb+jPYb/uVSNQP3ytoo+7
          XO7lMjGj++rQ+cqglSQrz6Tga4XFqseviYcWz11N8r5r6n7qww==
          -----END CERTIFICATE-----
          `);
      });

      if (onePage) {
        qz.websocket.connect().then(() => {
          const base64string = pdfBase64.split(",")[1];
          const printData = [
            "^XA\n",
            `^MN${stickyPaper ? "Y" : "N"}\n`,
            `^LL${totalHeight * 203.2}\n`,
            {
              type: "pdf",
              format: "base64",
              data: base64string,
              options: {
                language: "ZPL",
                pageHeight: totalHeight * 203.2,
                pageWidth: pageW * 203.2
              }
            },
            "^XZ\n"
          ];
          qz.print(config, printData)
            .catch()
            .then(() => {
              qz.websocket.disconnect();
            });
        });
      } else {
        qz.websocket.connect().then(() => {
          pdfList = pdfBase64.split("#####");
          for (const item of pdfList) {
            const base64string = item.split(",")[1];
            const printData = [
              "^XA\n",
              `^MN${stickyPaper ? "Y" : "N"}\n`,
              `^LL${totalHeight * 203.2}\n`,
              {
                type: "pdf",
                format: "base64",
                data: base64string,
                options: {
                  language: "ZPL",
                  pageHeight: totalHeight * 203.2,
                  pageWidth: pageW * 203.2
                }
              },
              "^XZ\n"
            ];
            qz.print(config, printData)
              .catch()
              .then(() => {
                qz.websocket.disconnect();
              });
          }
        });
      }
      return true;
    } catch {
      return false;
    }
  }

  public static async generateQrCode(value: any) {
    try {
      const qr = await QRCode.toDataURL(value, { errorCorrectionLevel: "H" });
      return qr;
    } catch (err) {
      console.error(err);
      return "";
    }
  }

  public static updateUserRoles(roles: any) {
    if (window.userInfo) {
      window.userInfo.roles = roles;
    }
  }

  public static generateVcf(
    name: string,
    surname: string,
    email: string,
    mobile: string,
    workPhone: string,
    homePhone: string,
    _gender: string,
    photo: string,
    nickname: string,
    note: string,
    organization: string,
    role: string,
    title: string,
    url: string,
    workUrl: string,
    homeUrl: string
  ): string {
    const vCard = new VCard();
    vCard.addName(surname, name);

    if (!_.isEmpty(email)) {
      vCard.addEmail(email);
    }

    if (!_.isEmpty(mobile)) {
      vCard.addPhoneNumber(mobile, "CELL");
    }

    if (!_.isEmpty(workPhone)) {
      vCard.addPhoneNumber(workPhone, "WORK");
    }

    if (!_.isEmpty(homePhone)) {
      vCard.addPhoneNumber(homePhone, "HOME");
    }

    if (!_.isEmpty(photo)) {
      vCard.addPhoto(photo);
    }

    if (!_.isEmpty(nickname)) {
      vCard.addNickname(nickname);
    }

    if (!_.isEmpty(note)) {
      vCard.addNote(note);
    }

    if (!_.isEmpty(organization)) {
      vCard.addCompany(organization);
    }

    if (!_.isEmpty(role)) {
      vCard.addRole(role);
    }

    if (!_.isEmpty(title)) {
      vCard.addJobtitle(title);
    }

    if (!_.isEmpty(url)) {
      vCard.addURL(url);
    }

    if (!_.isEmpty(workUrl)) {
      vCard.addURL(workUrl, "WORK");
    }

    if (!_.isEmpty(homeUrl)) {
      vCard.addURL(homeUrl, "HOME");
    }

    return vCard.toString();
  }

  public static downloadVcf(
    name: string,
    surname: string,
    email: string,
    mobile: string,
    workPhone: string,
    homePhone: string,
    gender: string,
    photo: string,
    nickname: string,
    note: string,
    organization: string,
    role: string,
    title: string,
    url: string,
    workUrl: string,
    homeUrl: string
  ): void {
    const vcfString = this.generateVcf(
      name,
      surname,
      email,
      mobile,
      workPhone,
      homePhone,
      gender,
      photo,
      nickname,
      note,
      organization,
      role,
      title,
      url,
      workUrl,
      homeUrl
    );

    const blob = new Blob([vcfString], {
      type: "text/vcard"
    });

    const objectUrl = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = objectUrl;
    a.download = `${name}_${surname}.vcf`;
    a.click();
  }

  public static isWeb(): boolean {
    return true;
  }

  public static isIOS(): boolean {
    return false;
  }

  public static isAndroid(): boolean {
    return false;
  }

  public static isOnline(): boolean {
    return true;
  }

  // ARITHMETIC

  public static increment(value, seed) {
    return this.toDecimal(undefined, value) + this.toDecimal(undefined, seed);
  }

  public static decrament(value, value2) {
    return this.toDecimal(undefined, value) - this.toDecimal(undefined, value2);
  }

  public static sum(value, value2) {
    return this.toDecimal(undefined, value) + this.toDecimal(undefined, value2);
  }

  public static subtract(value, value2) {
    return this.toDecimal(undefined, value) - this.toDecimal(undefined, value2);
  }

  public static divide(value, value2) {
    return this.toDecimal(undefined, value) / this.toDecimal(undefined, value2);
  }

  public static multiply(value, value2) {
    return this.toDecimal(undefined, value) * this.toDecimal(undefined, value2);
  }

  public static subArray(value, startingIndex: number, count: number) {
    if (!value) return null;

    if (Array.isArray(value) && value.every((item) => typeof item === "number")) {
      const inputVal: number[] = value;
      const retVal: number[] = [];

      for (let i = startingIndex; i < startingIndex + count; i++) {
        if (i >= 0 && i < inputVal.length) {
          retVal.push(inputVal[i]);
        }
      }

      return retVal;
    }
    if (typeof value === "string") {
      return this.subString(value, startingIndex, count).split("");
    }
    if (Array.isArray(value)) {
      const retVal: any[] = [];

      for (let i = startingIndex; i < startingIndex + count; i++) {
        if (i >= 0 && i < value.length) {
          retVal.push(value[i]);
        }
      }

      return retVal;
    }

    return value;
  }

  public static subString(value: string | null | undefined, startingIndex: number, count: number): any {
    if (value === null || value === undefined) return null;
    return value.substring(startingIndex, startingIndex + count);
  }

  public static async iyzicoInitializeCheckoutForm(
    screen: any,
    cllbck: any,
    userName: string,
    pricingPlanReferenceCode: string,
    phoneNumber: string,
    identityNumber: string,
    address: string,
    city: string,
    country: string,
    zipCode: string
  ) {
    const ad = { address1: address, address2: "", city, country, zipCode } as IIyzicoAddressDto;
    const u = {
      userName,
      pricingPlanReferenceCode,
      address: ad,
      phoneNumber,
      identityNumber
    } as IIyzicoCheckoutFormDto;
    await IyzicoService.CheckoutForm(u)
      .then((response) => {
        if (response && response.data && response.data.checkoutFormContent) {
          screen?.props.showIyzicoDrawer({ data: response.data }, cllbck, screen);
        } else {
          KuikaAppManager.hideSpinner(screen);
        }
      })
      .catch(() => {
        KuikaAppManager.hideSpinner(screen);
      });
  }

  public static generateLink(screenName?: string, parameters?: { [key: string]: any }) {
    let linkString = window.location.href;

    if (screenName) {
      let lastIndex = linkString.lastIndexOf("/");
      if (lastIndex !== -1) {
        linkString = linkString.substring(0, lastIndex);
        linkString += `/${screenName}`;
      }
    }

    if (Object.keys(parameters).length > 0) {
      linkString += "?";
    }

    let index: number = 0;
    for (const [key, value] of Object.entries(parameters)) {
      const escapedString: string = encodeURIComponent(String(value));
      linkString += `${key}=${escapedString}`;
      if (index < Object.keys(parameters).length - 1) {
        linkString += "&";
      }
      index += 1;
    }

    return linkString;
  }

  public static runUIVisionMacro(stringifiedMacro: string) {
    try {
      const json = JSON.parse(stringifiedMacro);

      const isExtensionLoaded = function () {
        const $root = document.documentElement;
        return !!$root && !!$root.getAttribute("data-kantu");
      };
      const openExternal = function (url) {
        const $el = document.createElement("a");
        $el.setAttribute("target", "_blank");
        $el.setAttribute("href", url);
        $el.style.position = "absolute";
        $el.style.top = "-9999px";
        $el.style.left = "-9999px";
        document.body.appendChild($el);
        $el.click();
        setTimeout(() => {
          $el.remove();
        }, 200);
      };
      const openWebsite = function () {
        openExternal("https://ui.vision/rpa/home/getrpa");
      };
      if (!isExtensionLoaded()) {
        if (confirm("UI.Vision RPA is not installed yet. Do you want to download it now?")) {
          return openWebsite();
        }
      } else {
        return window.dispatchEvent(
          new CustomEvent("kantuSaveAndRunMacro", {
            detail: {
              direct: 1,
              closeRpa: 1,
              json
            }
          })
        );
      }
    } catch (error) {
      KMainFunctions.exceptionHandler(error);
    }
  }

  public static async copyToClipboard(value?: string) {
    try {
      await navigator.clipboard.writeText(value);
    } catch (e) {
      KMainFunctions.exceptionHandler(e);
    }
  }

  public static signinwithgoogle(screen: any): Promise<{}> {
    screen.props.googleLogin();
    localStorage.setItem("isGoogleLoginInProgress", "true");
    localStorage.setItem("isGoogleLoginAborted", "false");

    const previousUser = _.cloneDeep(window.userInfo ?? {});

    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        if (localStorage.getItem("isGoogleLoginInProgress") === "false") {
          clearInterval(interval);
          if (localStorage.getItem("isGoogleLoginAborted") === "true") {
            localStorage.removeItem("isGoogleLoginInProgress");
            localStorage.removeItem("isGoogleLoginAborted");
            KuikaAppManager.hideSpinner(screen);
            reject();
          } else {
            resolve({});
          }

          if (previousUser && !_.isEqual(previousUser, window.userInfo)) {
            clearInterval(interval);
            localStorage.removeItem("isGoogleLoginInProgress");
            localStorage.removeItem("isGoogleLoginAborted");
            resolve({});
          }
        }
      }, 200);
    });
  }

  public static getPublicIpAddress(): string {
    try {
      // Send a cross-origin request to get the public IP address
      const request = new XMLHttpRequest();
      request.open("GET", "https://api.ipify.org?format=json", false);
      request.send(null);
      if (request.status === 200) {
        const response = JSON.parse(request.responseText);
        return response.ip;
      }
      return "";
    } catch (error) {
      KMainFunctions.exceptionHandler(error);
      return "";
    }
  }

  public static sendVerificationCode(screen: any) {
    UserService.SendVerificationCode({ email: USER, dontSendEmail: false, language: "" })
      .then(() => {})
      .catch((e) => {
        KMainFunctions.exceptionHandler(e);
      });

    return "Mail send";
  }

  public static generateOTP(userName: string) {
    const secret = this.convertToOtpSecret(userName.toLowerCase());

    let algorithm: "SHA-1" | "SHA-256" | "SHA-512" = "SHA-1";

    if (OTP_ALGORITHM === "SHA256") algorithm = "SHA-256";
    else if (OTP_ALGORITHM === "SHA512") algorithm = "SHA-512";

    return TOTP.generate(secret, {
      period: OTP_PERIOD,
      digits: OTP_DIGITS,
      algorithm
    }).otp;
  }

  public static generateAuthenticatorAppUri(userName?: string) {
    try {
      const language = localStorage.getItem("ml");
      if (!userName) {
        let message = "";
        switch (language) {
          case "en_US":
            message = "Unable to generate authenticator app (OTP) URI without username.";
            break;

          case "fr_FR":
            message = "Impossible de générer l'URI de l'application d'authentification (OTP) sans nom d'utilisateur.";
            break;

          case "tr_TR":
            message = "Kullanıcı adı olmadan Kimlik Doğrulayıcı (OTP) ugulama URI'yı oluşturulamaz.";
            break;
        }
        throw new Error(message);
      }

      if (!OTP_SECRET_KEY) {
        let message = "Authenticator (OTP) application settings are missing. Please contact your Administrator";
        switch (language) {
          case "en_US":
            message = "Authenticator (OTP) application settings are missing. Please contact your Administrator";
            break;

          case "fr_FR":
            message =
              "Les paramètres de l'application Authentificateur (OTP) sont manquants. Veuillez contacter votre administrateur";
            break;

          case "tr_TR":
            message = "Kimlik Doğrulayıcı (OTP) uygulama ayarları eksik. Lütfen Yöneticinizle iletişime geçin";
            break;
        }
        throw new Error(message);
      }
      const usrName = userName?.toString()?.toLowerCase() ?? "";
      const domainName = this.removeSpecialCharacter(window.kuika?.info?.workspace ?? "");
      const secret = this.convertToOtpSecret(usrName);
      const url = `otpauth://totp/${domainName}:${usrName}?secret=${secret}&issuer=${domainName}&algorithm=${OTP_ALGORITHM}&digits=${OTP_DIGITS}&period=${OTP_PERIOD}`;
      return url;
    } catch (error) {
      KMainFunctions.exceptionHandler(error);
      return "";
    }
  }

  public static convertToOtpSecret(userName: string) {
    const secretKey: string = `${userName.toLowerCase()}${OTP_SECRET_KEY}KUIKAMOBILEKEYKUIKAMOBILEKEY2345KUIKAMOBILEKEYKUIKAMOBILEKEY2345`;
    const secret = this.subString(this.ignoreNonAllowedCharacters4Base32Secret(secretKey), 0, 64);

    return secret ?? "";
  }

  public static async changeTwoFactorAuthenticationType(authenticationType) {
    try {
      await UserService.ChangeTwoFactorAuthenticationType({ authenticationType });
    } catch (error) {
      KMainFunctions.exceptionHandler(error);
    }
  }

  public static removeSpecialCharacter(value: string): string {
    return value.replace(/[^A-Za-z0-9 ]/g, "");
  }

  public static ignoreNonAllowedCharacters4Base32Secret(value: string) {
    return value.replace(/[^A-Za-z2-7 ]/g, "");
  }

  public static importTableConfigs = (screen, tableName, configJSON) => {
    const screenTable = Object.keys(screen).filter((v) => v.includes(tableName));
    const refTable = screenTable.find((v) => v.includes("kuikaTableRef") || v.includes("kuikaPivotTableRef"));
    if (
      screen[refTable] != undefined &&
      screen[refTable] != null &&
      screen[refTable].current != undefined &&
      screen[refTable].current != null &&
      screen[refTable].current.setTableConfigJson != undefined &&
      configJSON != undefined &&
      configJSON != null &&
      configJSON != ""
    ) {
      console.log(screen[refTable].current);
      screen[refTable].current.setTableConfigJson(configJSON);
    }
  };

  public static exportTableConfigs = (screen, tableName) => {
    const screenTable = Object.keys(screen).filter((v) => v.includes(tableName));
    const refTable = screenTable.find((v) => v.includes("kuikaTableRef") || v.includes("kuikaPivotTableRef"));
    if (
      screen[refTable] != undefined &&
      screen[refTable] != null &&
      screen[refTable].current != undefined &&
      screen[refTable].current != null &&
      screen[refTable].current.getTableConfigJson != undefined
    ) {
      return screen[refTable].current.getTableConfigJson();
    }
    return "";
  };

  public static clearTableFilteringConfigs = (screen, tableName) => {
    const screenTable = Object.keys(screen).filter((v) => v.includes(tableName));
    const refTable = screenTable.find((v) => v.includes("kuikaTableRef"));
    if (
      screen[refTable] != undefined &&
      screen[refTable] != null &&
      screen[refTable].current != undefined &&
      screen[refTable].current != null &&
      screen[refTable].current.clearFilterConfigs != undefined
    ) {
      screen[refTable].current.clearFilterConfigs();
    }
  };

  public static resetTableConfigs = (screen, tableName) => {
    const screenTable = Object.keys(screen).filter((v) => v.includes(tableName));
    const refTable = screenTable.find((v) => v.includes("kuikaTableRef") || v.includes("kuikaPivotTableRef"));
    if (
      screen[refTable] != undefined &&
      screen[refTable] != null &&
      screen[refTable].current != undefined &&
      screen[refTable].current != null &&
      screen[refTable].current.resetTableConfigs != undefined
    ) {
      screen[refTable].current.resetTableConfigs();
    }
  };

  public static readNFC = () => {
    KMainFunctions.displayWarningNotification("Action ReadNFC is not supported on web.");
    return null;
  };

  public static isInList(value1: unknown, value2: unknown): boolean {
    if (value1 === null || value1 === undefined || value2 === null || value2 === undefined) {
      return false;
    }
    if (Array.isArray(value2)) {
      for (let i = 0; i < value2.length; i++) {
        if (value2[i] === value1) {
          return true;
        }
      }
      return false;
    }
    return false;
  }

  public static async downloadFileFromServer(path, fileid) {
    await ServerFilesService.DownloadFile(path, fileid);
    return "";
  }

  public static async deleteFileFromServer(path, fileid) {
    return ServerFilesService.DeleteFile(path, fileid);
  }

  public static isDocumentReady() {
    switch (document.readyState) {
      case "complete":
        return true;
      default:
        return false;
    }
  }

  public static mqttpublish = (client, topic, message, qos, retain = false) => {
    if (!client || !client.connected) {
      console.error("MQTT client is not connected");
      return;
    }

    if (qos === undefined || qos === null) {
      qos = 0;
    }

    qos = Number(qos);

    client.publish(topic, message, { qos, retain }, (error) => {
      if (error) {
        console.error("MQTT publish failed:", error);
      }
    });
  };

  public static mqttsubscribe = async (client, subscribeAction, topic) => {
    return new Promise((resolve, reject) => {
      const mqttTopic = topic;

      if (!client._resubscribeTopics || !client._resubscribeTopics[topic]) {
        client.subscribe(topic, { nl: true }, (err) => {
          if (err) {
            resolve({ isSuccess: false });
          }

          client.on("message", (topic, message) => {
            if (mqttTopic === topic) {
              subscribeAction(message);
            }
          });

          resolve({ isSuccess: true });
        });
      } else {
        resolve({ isSuccess: true });
      }
    });
  };

  public static getMqttClientId(clientName, screenName) {
    var mqttClientId = this.readFromLocalStorage(`${clientName}_${screenName}_MQTT_CLIENT_ID`);
    if (mqttClientId === undefined || mqttClientId === null) {
      mqttClientId = Guid.create().toString();
      this.writeToLocalStorage(`${clientName}_${screenName}_MQTT_CLIENT_ID`, mqttClientId);
    }
    return mqttClientId;
  }
}
